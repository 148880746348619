<template>
  <div class="d-flex flex-column justify-content-center align-items-center col-12 mt-3">
    <div class="d-flex flex-row justify-content-around col-12">
      <button class="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-2 overview-button">Overview</button>
      <input v-model="dateRangePicked" class="col-4 col-md-4 col-sm-5 col-xl-3" placeholder="Select Date..." type="text" id="datepicker" autocomplete="off"/>
      <button @click="confirmDateRangePicked(dateRangePicked)" class="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-2 confirm-button">Confirm</button>
    </div>
    <div class="d-flex flex-column align-items-center col-12 mt-2">
      <div class="d-flex flex-column col-11">
        <div class="service-header mb-1">Please select a game:</div>
        <div class="col-4">
          <select @change="setProductEngagementData($event)" v-model="gameId" class="product-select-dropdown">
            <option value="1">Hangman</option>
            <option value="2">Math</option>
            <option value="3">Trivia</option>
            <option value="4">Unscramble</option>
            <option value="5">AI Trivia</option>
            <option value="8">Xs & Os</option>
            <option value="10">Battle Ships</option>
            <option value="7">Acme Bank</option>
          </select>
        </div>
      </div>
      <div class="d-flex flex-column align-items-center col-12 mt-2">
        <BarChart :engagementMetric="engagementMetric.dateDailyBreakdown"></BarChart>
      </div>
      <div class="d-flex flex-column col-12 mt-4">
        <div class="d-flex flex-row justify-content-center flex-wrap">
          <div class="d-flex flex-column total-clicks-header metric-container col-3">
            <div class="game-name-text">{{ engagementMetricsData.Description }}</div>
            <div class="metric-container-header p-1 ">Total Players</div>
            <div class="metric-value-text m-1">{{ new Intl.NumberFormat('en-US').format(engagementMetricsData.TotalPlayers) }}</div>
          </div>
          <div class="d-flex flex-column metric-container col-3">
            <div class="metric-container-header p-1">Total Interactions</div>
            <div class="metric-value-text m-1">{{ new Intl.NumberFormat('en-US').format(engagementMetricsData.TotalInteractions) }}</div>
          </div>
          <div class="d-flex flex-column metric-container col-3">
            <div class="metric-container-header p-1">Completed Total Plays</div>
            <div class="metric-value-text m-1">{{ new Intl.NumberFormat('en-US').format(engagementMetricsData.TotalCompletedPlays) }}</div>
          </div>
          <div class="d-flex flex-column metric-container col-3">
            <div class="metric-container-header p-1">Completed Unique Plays</div>
            <div class="metric-value-text m-1">{{ new Intl.NumberFormat('en-US').format(engagementMetricsData.UniqueCompletedPlays) }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column align-items-center mt-2 mb-2 col-12">
      <div class="col-11" style="overflow-x:auto;">
        <table class="table table-hover" id="tblToExcl">
          <thead>
          <tr>
            <th class="col-2" scope="col">Date</th>
            <th class="col-2" scope="col">Players</th>
            <th class="col-3" scope="col">Interactions</th>
            <th class="col-3" scope="col">Total Plays</th>
            <th class="col-2" scope="col">Unique Plays</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(day, index) in dailyEngagementTable.slice().reverse()" :key=index>
            <td> {{ Helpers.dateConversion(day.Date.slice(0, 10)) }}</td>
            <td> {{ new Intl.NumberFormat('en-US').format(day.Players) }}</td>
            <td> {{ new Intl.NumberFormat('en-US').format(day.TotalInteractions) }}</td>
            <td> {{ new Intl.NumberFormat('en-US').format(day.TotalCompletedPlays) }}</td>
            <td> {{ new Intl.NumberFormat('en-US').format(day.UniqueCompletedPlays) }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <button class="d-flex flex-column justify-content-center export-button p-2 mb-3" @click="htmlTableToExcel()">Export to Excel</button>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import DashboardService from "@/services/dashboardService";
import {mapMutations, mapState} from "vuex";
import BarChart from "@/views/components/charts/BarChart";
import flatpickr from "flatpickr";
import * as XLSX from 'xlsx/xlsx.mjs';

export default {
  name: "GameToWinEngagement",
  inheritAttrs: false,
  props: ['engagementMetric'],
  components: {BarChart},
  computed: {
    ...mapState(['productConfig', 'startingDateCalendar', 'endingDateCalendar'])
  },
  data() {
    return {
      dateRangePicked: `${moment().subtract(7, 'd').format('YYYY-MM-DD')} to ${moment().subtract(1, 'd').format('YYYY-MM-DD')}`,
      startingDatePicked: undefined,
      endDatePicked: undefined,
      productId: 1,
      gameId: 1,
      engagementMetricsData: [],
      dailyEngagementTable: [],
    }
  },
  methods: {
    ...mapMutations(['setTriggerNewChart', 'setBarChartData', 'setBarChartToLoad', 'setStartingDateCalendar', 'setEndingDateCalendar']),
    async confirmDateRangePicked(dateRange) {
      this.sliceDateRange(dateRange);
      await this.setEngagementMetrics();
      this.setTriggerNewChart(true);
      this.setBarChartData(this.engagementMetricsData.dateDailyBreakdown);
    },
    sliceDateRange(dateRange) {
      this.startingDatePicked = dateRange.slice(0, 10)
      this.endDatePicked = dateRange.substr(-10)
      this.setStartingDateCalendar(this.startingDatePicked)
      this.setEndingDateCalendar(this.endDatePicked)
    },
    async setEngagementMetrics() {
      let response = await DashboardService.getGameToWinEngagementStats(`${this.startingDatePicked}`, `${this.endDatePicked}`, this.productId, this.gameId)
      this.engagementMetricsData = response.data;
      this.dailyEngagementTable = this.engagementMetricsData.dateDailyBreakdown;
    },
    async setProductEngagementData(event) {
      this.gameId = event.target.value;
      this.startingDatePicked = this.dateRangePicked.slice(0, 10)
      this.endDatePicked = this.dateRangePicked.substr(-10)
      await this.setEngagementMetrics()
      this.setTriggerNewChart(true)
      this.setBarChartData(this.engagementMetricsData.dateDailyBreakdown)
    },
    async checkDateRangeChanged() {
      if (this.startingDateCalendar !== undefined) {
        this.setUpdatedDateRange();
        await this.setEngagementMetrics();
        this.triggerUpdatedChart();
      } else {
        this.setDefaultEngagementMetrics();
        this.setBarChartToLoad(this.productConfig.engagementGraph);
      }
    },
    setUpdatedDateRange() {
      this.dateRangePicked = `${this.startingDateCalendar} to ${this.endingDateCalendar}`;
      this.startingDatePicked = this.startingDateCalendar;
      this.endDatePicked = this.endingDateCalendar;
    },
    triggerUpdatedChart() {
      this.setTriggerNewChart(true);
      this.setBarChartData(this.engagementMetricsData.dateDailyBreakdown);
      this.setBarChartToLoad(this.productConfig.engagementGraph);
    },
    setDefaultEngagementMetrics() {
      this.engagementMetricsData = this.engagementMetric;
      this.dailyEngagementTable = this.engagementMetricsData.dateDailyBreakdown
    },
    htmlTableToExcel() {
      let table_elt = document.getElementById("tblToExcl");
      let workbook = XLSX.utils.table_to_book(table_elt);
      XLSX.writeFile(workbook, `Game 2 Win Engagement (${this.engagementMetricsData.Description}).xlsb`);
    },
  },
  async beforeMount() {
    await this.checkDateRangeChanged()
  },
  mounted() {
    flatpickr('#datepicker', {
      mode: "range"
    })
  },
}
</script>

<style scoped>
.product-select-dropdown {
  height: 32px;
  width: 130px;
}

.overview-button, .confirm-button, .export-button {
  height: 35px;
  border-radius: 18px;
  font-weight: 600;
  font-size: 20px;
  border: 0;
  background-color: #EE5C36;
  color: #FFFFFF;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

input {
  text-align: center;
}

.metric-container {
  border-radius: 12px;
  font-weight: 600;
  border: 0;
  background-color: #F4D1CE;
  color: #000000;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 250px;
  margin: 20px;
}

.metric-container-header {
  font-size: 18px;
}

.metric-value-text {
  font-size: 30px;
  padding-left: 3px;
}

.total-clicks-header {
  position: relative;
  font-size: 25px;
}

.game-name-text {
  left: 5px;
  top: -40px;
  position: absolute;
}

.service-header {
  font-size: 20px;
  font-weight: 700;
  margin-top: 10px;
}

@media only screen and (max-width: 576px) {
  .overview-button, .confirm-button {
    height: 28px;
    font-size: 18px;
  }

  input {
    font-size: 10.5px;
  }

  .metric-container-header {
    font-size: 13px;
  }

  .metric-value-text {
    font-size: 16px;
  }

  .metric-container {
    width: 25%;
    margin-bottom: -5px;
  }

  .total-clicks-header {
    font-size: 20px;
  }

  .game-name-text {
    left: 5px;
    top: -30px;
    position: absolute;
    font-size: 15.5px;
    width: 150px;
  }

  table {
    margin-top: 20px;
    font-size: 10.5px;
  }

  .export-button {
    height: 28px;
    font-size: 15px;
  }

}
</style>
