<template>
  <div class="d-flex flex-column justify-content-center col-12 mt-3">
    <div class="d-flex flex-row justify-content-around col-12">
      <button class="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-2 overview-button">Overview</button>
      <input v-model="dateRangePicked" class="col-4 col-md-4 col-sm-5 col-xl-3" placeholder="Select Date..." type="text"
             id="datepicker" autocomplete="off"/>
      <button @click="confirmDateRangePicked(dateRangePicked)"
              class="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-2 confirm-button">Confirm
      </button>
    </div>
    <div class="d-flex flex-row justify-content-center">
      <BarChart :engagementMetric="engagementMetric[1]"></BarChart>
    </div>
    <div class="d-flex flex-row justify-content-evenly" v-if="engagementMetricData.length > 0">
      <div
          class="d-flex flex-column engagement-metric-container p-1 col-3 col-xs-3 col-sm-3 col-md-2 col-lg-3 col-xl-2 mt-3">
        <div class="metric-container-header p-1">Total Logins</div>
        <div class="metric-value-text p-1">
          {{ new Intl.NumberFormat('en-US').format(this.engagementMetricData[0][0].UniqueLogins) }}
        </div>
        <div class="d-flex flex-row text-center align-items-center pb-1">
          <div class="col-2"><img class="col-6"
                                  :src="Helpers.findArrowImageModule(Helpers.deltaPercentage(this.engagementMetricData[0][0].UniqueLogins , this.engagementMetricData[0][0].UniqueLoginsDelta))">
          </div>
          <div
              :class="`${Helpers.setTextColour(Helpers.deltaPercentage(this.engagementMetricData[0][0].UniqueLogins , this.engagementMetricData[0][0].UniqueLoginsDelta)) ? 'green-text' : 'red-text' }`">
            {{
              Helpers.deltaPercentage(this.engagementMetricData[0][0].UniqueLogins, this.engagementMetricData[0][0].UniqueLoginsDelta)
            }}
          </div>
        </div>
      </div>
      <div
          class="d-flex flex-column engagement-metric-container p-1 col-3 col-xs-3 col-sm-3 col-md-2 col-lg-3 col-xl-2 mt-3">
        <div class="metric-container-header p-1">Total Views</div>
        <div class="metric-value-text p-1">
          {{ new Intl.NumberFormat('en-US').format(this.engagementMetricData[0][0].Views) }}
        </div>
        <div class="d-flex flex-row text-center align-items-center pb-1">
          <div class="col-2"><img class="col-6"
                                  :src="Helpers.findArrowImageModule(Helpers.deltaPercentage(this.engagementMetricData[0][0].Views , this.engagementMetricData[0][0].ViewsDelta))">
          </div>
          <div
              :class="`${Helpers.setTextColour(Helpers.deltaPercentage(this.engagementMetricData[0][0].Views , this.engagementMetricData[0][0].ViewsDelta)) ? 'green-text' : 'red-text' }`">
            {{
              Helpers.deltaPercentage(this.engagementMetricData[0][0].Views, this.engagementMetricData[0][0].ViewsDelta)
            }}
          </div>
        </div>
      </div>
      <div
          class="d-flex flex-column engagement-metric-container p-1 col-3 col-xs-3 col-sm-3 col-md-2 col-lg-3 col-xl-2 mt-3">
        <div class="metric-container-header p-1">Video Progress</div>
        <div class="metric-value-text p-1">
          {{ new Intl.NumberFormat('en-US').format(this.engagementMetricData[0][0].AverageProgress) }}s
        </div>
        <div class="d-flex flex-row text-center align-items-center pb-1">
          <div class="col-2"><img class="col-6"
                                  :src="Helpers.findArrowImageModule(Helpers.deltaPercentage(this.engagementMetricData[0][0].AverageProgress , this.engagementMetricData[0][0].AverageProgressDelta))">
          </div>
          <div
              :class="`${Helpers.setTextColour(Helpers.deltaPercentage(this.engagementMetricData[0][0].AverageProgress , this.engagementMetricData[0][0].AverageProgressDelta)) ? 'green-text' : 'red-text' }`">
            {{
              Helpers.deltaPercentage(this.engagementMetricData[0][0].AverageProgress, this.engagementMetricData[0][0].AverageProgressDelta)
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BarChart from "@/views/components/charts/BarChart";
import flatpickr from "flatpickr";
import DashboardService from "@/services/dashboardService";
import {mapMutations, mapState} from "vuex";
import moment from "moment";

export default {
  name: "MasterStreamEngagement",
  inheritAttrs: false,
  props: ['engagementMetric'],
  components: {
    BarChart
  },
  computed: {
    ...mapState(['productConfig', 'startingDateCalendar', 'endingDateCalendar'])
  },
  data() {
    return {
      dateRangePicked: `${moment().subtract(7, 'd').format('YYYY-MM-DD')} to ${moment().subtract(1, 'd').format('YYYY-MM-DD')}`,
      engagementMetricData: [],
      startingDatePicked: undefined,
      endDatePicked: undefined,
    }
  },
  methods: {
    ...mapMutations(['setIsLoading', 'setTriggerNewChart', 'setBarChartData', 'setBarChartToLoad', 'setStartingDateCalendar', 'setEndingDateCalendar']),
    async confirmDateRangePicked(dateRange) {
      this.sliceDateRange(dateRange);
      await this.setNewEngagementMetrics();
      this.setTriggerNewChart(true);
      this.setBarChartData(this.engagementMetricData[1]);
    },
    sliceDateRange(dateRange) {
      this.startingDatePicked = dateRange.slice(0, 10);
      this.endDatePicked = dateRange.substr(-10);
      this.setStartingDateCalendar(this.startingDatePicked);
      this.setEndingDateCalendar(this.endDatePicked);
    },
    setUpdatedDateRange() {
      this.dateRangePicked = `${this.startingDateCalendar} to ${this.endingDateCalendar}`;
      this.startingDatePicked = this.startingDateCalendar;
      this.endDatePicked = this.endingDateCalendar;
    },
    async setNewEngagementMetrics() {
      this.engagementMetricData = (await DashboardService.getExternalEngagementData(`${this.startingDatePicked}`, `${this.endDatePicked}`, this.productConfig.productId, this.productConfig.serviceId, 0)).data;
    },
    triggerUpdatedChart() {
      this.setTriggerNewChart(true);
      this.setBarChartData(this.engagementMetricData[1]);
      this.setBarChartToLoad(this.productConfig.engagementGraph);
    },
    setDefaultEngagementMetrics() {
      this.engagementMetricData = this.engagementMetric;
    },
    async checkDateRangeChanged() {
      if (this.startingDateCalendar !== undefined) {
        this.setUpdatedDateRange()
        await this.setNewEngagementMetrics()
        this.triggerUpdatedChart();
      } else {
        this.setDefaultEngagementMetrics();
        this.setBarChartToLoad(this.productConfig.engagementGraph);
      }
    },
  },
  mounted() {
    flatpickr('#datepicker', {
      mode: "range"
    })
  },
  async beforeMount() {
    await this.checkDateRangeChanged();
  }
}
</script>

<style scoped>
.overview-button, .confirm-button {
  height: 35px;
  border-radius: 18px;
  font-weight: 600;
  font-size: 20px;
  border: 0;
  background-color: #EE5C36;
  color: #FFFFFF;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

input {
  text-align: center;
}

.engagement-metric-container, .engagement-metric-container-bar {
  border-radius: 12px;
  font-weight: 600;
  border: 0;
  background-color: #F4D1CE;
  color: #000000;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.metric-container-header, .red-text, .green-text {
  font-size: 18px;
}

.metric-value-text {
  font-size: 30px;
}

.red-text {
  color: #ff0000;
}

.green-text {
  color: #207520;
}

@media only screen and (max-width: 576px) {
  .metric-container-header, .red-text, .green-text {
    font-size: 12px;
  }

  .metric-value-text {
    font-size: 12px;
  }

  table {
    margin-top: 20px;
    font-size: 10.5px;
  }

  .overview-button, .confirm-button {
    height: 28px;
    font-size: 18px;
  }

  .engagement-metric-container {
    width: 24% !important;
  }

  input {
    font-size: 10.5px;
  }
}

</style>
