<template>
  <div>
    <canvas id="bar-chart"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js'
import {loadBarChartData} from "@/functions/charts/loadBarChartData";
import {mapMutations, mapState} from "vuex";

export default {
  name: 'BarChart',
  inheritAttrs: false,
  props: ['revenueMetric', 'engagementMetric', 'ussdWebDataMetric', 'awardsMetric', 'subscriptionMetric'],
  computed: {
    ...mapState(['triggerNewChart', 'barChartDataUpdated', 'barChartToLoad', 'productConfig', 'reportingMetric'])
  },
  data() {
    return {
      barChartData: undefined
    }
  },
  methods: {
    ...mapMutations(['setTriggerNewChart']),
    checkMetricDataToLoad() {
      let data = [this.awardsMetric, this.revenueMetric, this.engagementMetric, this.ussdWebDataMetric, this.subscriptionMetric];
      let conditionsFiltering = [null, undefined, 0];
      this.metricDataToLoad = data.filter((item) => !conditionsFiltering.includes(item))[0]
    },
    getGraphLabel() {
      const graphMapping = {
        productEngagement: this.productConfig.engagementGraph,
        productEngagementHourly : this.productConfig.totalPlaysGraph,
        productRevenue: this.productConfig.revenueGraph,
        ussdWebComparison: this.productConfig.ussdWebGraph,
        awardSpend: this.productConfig.awardsGraph,
        productSubscription: this.productConfig.bannerClicksGraph
      };
      return graphMapping[this.reportingMetric];
    }
  },
  mounted() {
    const ctx = document.getElementById('bar-chart');
    this.myChart = new Chart(ctx, this.barChartData);
  },
  beforeMount() {
    this.checkMetricDataToLoad();
    this.barChartData = loadBarChartData(this.metricDataToLoad, this.getGraphLabel(), this.productConfig.currencySymbol)
  },
  watch: {
    '$store.state.triggerNewChart': function () {
      if (this.$store.state.triggerNewChart === true) {
        this.myChart.destroy()
        const ctx = document.getElementById('bar-chart');
        this.barChartData = loadBarChartData(this.barChartDataUpdated, this.barChartToLoad, this.productConfig.currencySymbol)
        this.myChart = new Chart(ctx, this.barChartData);
        this.setTriggerNewChart(false)
      }
    }
  }
}
</script>

<style>
</style>
