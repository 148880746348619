import axios from "axios";
import {environmentConfig} from "@/config/enviromentConfig";

export default class UserDataService {
    static async sendOtp(msisdn) {
        return await axios.post(`${environmentConfig.dashboardService}/send-otp`, {
                msisdn
            },
        )
    }

    static async loginUser(msisdn, otp) {
        return await axios.post(`${environmentConfig.dashboardService}/login-user`, {
                msisdn,
                otp
            },
        )
    }

}
