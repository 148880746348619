<template>
  <div class="d-flex flex-column" v-if="!this.isLoading">
    <div class="d-flex flex-column align-items-center align-content-center text-center justify-content-center header-container">
      <div class="d-flex flex-row justify-content-center align-items-center">
        <img class="header-product-image" :src="require(`../../assets/products/${productConfig.endpoint.toLowerCase()}-logo.png`)">
        <div class="m-2 header-title">{{ productConfig.productTitle }}</div>
      </div>
      <div class="nav-bar-container">
        <nav class="navbar navbar-light">
          <div class="container-fluid">
            <button class="navbar-toggler" type="button" @click="sideBarMenuClicked()">
              <span class="navbar-toggler-icon"></span>
            </button>
          </div>
        </nav>
      </div>
    </div>
    <div @click="setSidebarMenuVisible(false)" class="dashboard-container">
      <router-view/>
    </div>
    <Transition name="slide-burger-menu">
      <div class="d-flex flex-column sidebar-container" v-if="sideBarMenuVisible">
        <SidebarMenu/>
      </div>
    </Transition>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import SidebarMenu from "@/views/components/SidebarMenu";

export default {
  name: "PenroseDashboard",
  components: {SidebarMenu},
  computed: {
    ...mapState(['isLoading', 'sideBarMenuVisible', 'productConfig'])
  },
  data() {
    return {
      containerScroll: 'scroll'
    }
  },
  methods: {
    ...mapMutations(['setIsLoading', 'setSidebarMenuVisible']),
    sideBarMenuClicked() {
      if (this.sideBarMenuVisible === false) {
        this.setSidebarMenuVisible(true)
      }
    },
  },
  watch: {
    '$store.state.sideBarMenuVisible': function () {
      if (this.$store.state.sideBarMenuVisible) {
        this.containerScroll = 'hidden'
      } else this.containerScroll = 'scroll'
    }
  },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400&display=swap');

.sidebar-container {
  position: absolute;
  height: 100%;
  width: 350px;
  z-index: 1;
}

.header-container {
  position: relative;
  height: 60px;
}

.nav-bar-container {
  position: absolute;
  top: 0;
  width: 100%;
}

.dashboard-container::-webkit-scrollbar {
  display: none;
}

.dashboard-container {
  position: fixed;
  height: 100%;
  padding-bottom: 70px;
  top: 60px;
  overflow-x: hidden;
  overflow-y: v-bind('containerScroll');
  left: 0;
  right: 0;
}

.slide-burger-menu-leave-active, .slide-burger-menu-enter-active {
  transition: 1s;
  z-index: 1;
}

.slide-burger-menu-enter-from {
  transform: translate(-100%, 0);
}

.slide-burger-menu-leave-to {
  transform: translate(-100%, 0);
}

.header-product-image {
  width: 50px;
}

.header-title {
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: 700;
}

@media only screen and (max-width: 400px) {
  .header-title {
    font-size: 15.5px;
  }
}
</style>
