<template>
  <div class="d-flex flex-column col-lg-12 mt-2">
    <div v-if="campaignSuccessPopup" class="blur col-lg-12"></div>
    <div v-if="campaignSuccessPopup" class="d-flex flex-column align-items-center col-lg-3">
      <div class="d-flex flex-column align-items-center text-center campaign-success-popup col-lg-3">
        <div class="col-lg-12" style="border: 1px solid #aaaaaa; background-color: #FFFFFF">
          <div class="mt-1">Your campaign was created successfully!</div>
          <div class="mt-3">Campaign ID: <span class="fw-bold">{{ campaignId }}</span></div>
          <div>Region: <span class="fw-bold">{{ this.region }}</span></div>
          <div>Network: <span class="fw-bold">{{ this.network }}</span></div>
          <div>Product: <span class="fw-bold">{{ this.product }}</span></div>
          <div>Channel: <span class="fw-bold">{{ this.channelMechanism }}</span></div>
          <div class="mb-2">Marketing Partner: <span class="fw-bold">{{ this.marketingPartner }}</span></div>
        </div>
        <button @click="closeBtnClicked()" class="close-btn mt-2 col-lg-7">Close</button>
      </div>
    </div>
    <div class="d-flex flex-row col-lg-9 col-12 flex-wrap">
      <div class="d-flex flex-column col-lg-6 col-12">
        <div class="m-1">Region</div>
        <div class="d-flex flex-column justify-content-center col-lg-10 select-box">
          <div @click="toggleDropdown('regionDropDown')" class="d-flex flex-column option-text">{{ region }}</div>
          <img src="@/assets/dropdown-icon.png" class="arrow-img">
          <div v-if="regionDropDown" class="dropdown-container col-lg-12">
            <div v-for="option in regionOptions" :key="option"
                 class="d-flex flex-column justify-content-center col-lg-12 select-box-dropdown">
              <div @click="regionSelected(option)" class="d-flex flex-column option-text">{{ option }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column col-lg-6 col-12">
        <div class="m-1">Network</div>
        <div class="d-flex flex-column justify-content-center col-lg-10 select-box">
          <div @click="toggleDropdown('networkDropDown')" class="d-flex flex-column option-text">{{ network }}</div>
          <img src="@/assets/dropdown-icon.png" class="arrow-img">
          <div v-if="networkDropDown" class="dropdown-container col-lg-12">
            <div @click="networkSelected(option)" v-for="option in networkOptions" :key="option"
                 class="d-flex flex-column justify-content-center col-lg-12 select-box-dropdown">
              <div class="d-flex flex-column option-text">{{ option }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-row col-lg-9 col-12 mt-2 flex-wrap">
      <div class="d-flex flex-column col-lg-6 col-12">
        <div class="m-1">Product Name</div>
        <div class="d-flex flex-column justify-content-center col-lg-10 select-box">
          <div @click="toggleDropdown('productDropDown')" class="d-flex flex-column option-text">{{ product }}</div>
          <img src="@/assets/dropdown-icon.png" class="arrow-img">
          <div v-if="productDropDown" class="dropdown-container col-lg-12">
            <div @click="productSelected(option)" v-for="option in productOptions" :key="option"
                 class="d-flex flex-column justify-content-center col-lg-12 select-box-dropdown">
              <div class="d-flex flex-column option-text">{{ option }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column col-lg-6 col-12">
        <div class="m-1">Channel Mechanism</div>
        <div class="d-flex flex-column justify-content-center col-lg-10 select-box">
          <div @click="toggleDropdown('channelMechanismDropDown')" class="d-flex flex-column option-text">
            {{ channelMechanism }}
          </div>
          <img src="@/assets/dropdown-icon.png" class="arrow-img">
          <div v-if="channelMechanismDropDown" class="dropdown-container col-lg-12">
            <div @click="channelMechanismSelected(option)" v-for="option in channelMechanismOptions" :key="option"
                 class="d-flex flex-column justify-content-center col-lg-12 select-box-dropdown">
              <div class="d-flex flex-column option-text">{{ option }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-row col-lg-9 col-12 flex-wrap mt-2">
      <div class="d-flex flex-column col-lg-6 col-12">
        <div class="m-1">Marketing Partner</div>
        <div class="d-flex flex-column justify-content-center col-lg-10 select-box">
          <div @click="toggleDropdown('marketingPartnerDropDown')" class="d-flex flex-column option-text">
            {{ marketingPartner }}
          </div>
          <img src="@/assets/dropdown-icon.png" class="arrow-img">
          <div v-if="marketingPartnerDropDown" class="dropdown-container col-lg-12">
            <div @click="marketingPartnerSelected(option)" v-for="option in marketingPartnerOptions" :key="option"
                 class="d-flex flex-column justify-content-center col-lg-12 select-box-dropdown">
              <div class="d-flex flex-column option-text">{{ option }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column col-lg-6 col-12">
        <div class="m-1">Custom Description (optional)</div>
        <input @click="toggleDropdown(null)" placeholder="Enter Custom Description" v-model="customDescription"
               class="d-flex flex-column justify-content-center col-lg-10 select-box">
      </div>
    </div>
    <div class="d-flex flex-column col-lg-5 mt-5">
      <div class="d-flex flex-row justify-content-start col-lg-8">
        <button @click="createCampaignIdClicked()" class="col-lg-7 create-campaign-btn">Create Campaign ID</button>
      </div>
      <div style="color: red" v-if="createCampaignError === true" class="fw-bold">Error: Create Campaign Error. Please
        try again
      </div>
    </div>
  </div>
</template>

<script>
import DashboardService from "@/services/dashboardService";
import {mapState} from "vuex";

export default {
  name: "CreateCampaignIDs",
  computed: {
    ...mapState(['userData'])
  },
  data() {
    return {
      region: 'ZA',
      regionDropDown: false,
      regionOptions: ['ZA', 'Other'],
      network: 'Select Network Name',
      networkDropDown: false,
      networkOptions: undefined,
      product: 'Enter Product Name',
      productDropDown: false,
      productOptions: undefined,
      channelMechanism: 'Select Channel Mechanism',
      channelMechanismDropDown: false,
      channelMechanismOptions: undefined,
      marketingPartner: 'Select Marketing Partner',
      marketingPartnerDropDown: false,
      marketingPartnerOptions: undefined,
      customDescription: undefined,
      campaignSuccessPopup: false,
      campaignId: undefined,
      createCampaignError: undefined,
    }
  },
  methods: {
    toggleDropdown(dropdownName) {
      const dropdowns = ['regionDropDown', 'networkDropDown', 'productDropDown', 'channelMechanismDropDown', 'marketingPartnerDropDown'];
      dropdowns.forEach(dropdown => {
        this[dropdown] = dropdown === dropdownName ? !this[dropdown] : false;
      });
    },
    regionSelected(region) {
      this.region = region;
      this.toggleDropdown('regionDropDown');
    },
    networkSelected(region) {
      this.network = region;
      this.toggleDropdown('networkDropDown');
    },
    productSelected(product) {
      this.product = product;
      this.toggleDropdown('productDropDown');
    },
    channelMechanismSelected(channelMechanism) {
      this.channelMechanism = channelMechanism;
      this.toggleDropdown('channelMechanismDropDown');
    },
    marketingPartnerSelected(marketingPartner) {
      this.marketingPartner = marketingPartner;
      this.toggleDropdown('marketingPartnerDropDown');
    },
    async createCampaignIdClicked() {
      const validCampaign = this.checkValidInputParams()
      if (validCampaign === false) return this.createCampaignError = true;
      const {CampaignId} = await DashboardService.addCampaign(this.region, this.network, this.product, this.channelMechanism, this.marketingPartner, this.customDescription, this.userData.LoginName);
      if (CampaignId) {
        this.createCampaignError = false;
        this.campaignSuccessPopup = true;
        this.campaignId = CampaignId;
      }
    },
    checkValidInputParams() {
      return (
          this.network !== 'Select Network Name' &&
          this.product !== 'Enter Product Name' &&
          this.marketingPartner !== 'Select Marketing Partner' &&
          this.channelMechanism !== 'Select Channel Mechanism' &&
          this.customDescription !== undefined
      );
    },
    closeBtnClicked() {
      this.network = 'Select Network Name';
      this.product = 'Enter Product Name';
      this.marketingPartner = 'Select Marketing Partner';
      this.channelMechanism = 'Select Channel Mechanism';
      this.customDescription = undefined;
      this.campaignSuccessPopup = false;
    },
    async configureCampaignDropDowns() {
      const response = await DashboardService.getCampaignsByDropDowns();
      this.networkOptions = response[0].NetworkNameFilters;
      this.productOptions = response[1].productToFilters;
      this.channelMechanismOptions = response[2].Channel_MechanismFilters;
      this.marketingPartnerOptions = response[3].MarketingPartner_ProviderFilters;
    }
  },
  async beforeMount() {
    await this.configureCampaignDropDowns();
  }
}
</script>

<style scoped>
.select-box {
  position: relative;
  height: 50px;
  border: 1px solid #aaaaaa;
}

.select-box-dropdown {
  height: 50px;
  border-left: 1px solid #aaaaaa;
  border-right: 1px solid #aaaaaa;
  border-bottom: 1px solid #aaaaaa;
}

.option-text, ::placeholder {
  margin-left: 10px;
  color: #aaaaaa;
}

.dropdown-container {
  position: absolute;
  z-index: 1;
  top: 50px;
  background-color: #fffdfd;
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-color: #EE5C36 #e1e1e1;
  scrollbar-width: thin;
  width: 100.5%;
}

.arrow-img {
  position: absolute;
  right: 15px;
  height: 10px;
}

.create-campaign-btn {
  height: 50px;
  color: #FFFFFF;
  background-color: #EE5C36;
  border: 1px solid #aaaaaa;
}

input {
  padding-left: 10px;
}

.blur {
  position: absolute;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(1px);
  top: 0;
  height: 140vh;
  z-index: 1;
}

.campaign-success-popup {
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  font-size: 14px;
  font-weight: 400;
}

.close-btn {
  color: #FFFFFF;
  background-color: #EE5C36;
  border: 0;
  height: 45px;
  font-size: 15px;
}

@media only screen and (max-width: 800px) {
  .select-box {
    height: 35px;
  }

  .select-box-dropdown {
    height: 35px;
  }


  .create-campaign-btn {
    height: 35px;
  }

  .dropdown-container {
    top: 35px;
    width: 100%;
  }
}
</style>
