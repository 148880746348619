<template>
  <div class="d-flex flex-column justify-content-center align-items-center col-12 mt-3">
    <div class="d-flex flex-row justify-content-around col-12">
      <button class="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-2 overview-button">Overview</button>
      <input v-model="dateRangePicked" class="col-4 col-md-4 col-sm-5 col-xl-3" placeholder="Select Date..." type="text" id="datepicker" autocomplete="off"/>
      <button @click="confirmDateRangePicked(dateRangePicked)" class="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-2 confirm-button">Confirm</button>
    </div>
    <div class="d-flex flex-column col-11 mt-3">
      <div class="m-3 engagement-header">Daily 5</div>
      <div class="d-flex flex-row justify-content-start m-1">
        <div class="d-flex flex-column engagement-metric-container p-1 col-3 col-xs-3 col-sm-3 col-md-2 col-lg-3 col-xl-2 m-2">
          <div class="metric-container-header p-1">Unique Players</div>
          <div class="metric-value-text p-1">{{ new Intl.NumberFormat('en-US').format(engagementMetricData.TotalUniquePlayers) }}</div>
          <div class="d-flex flex-row text-center align-items-center pb-1">
            <div class="col-2"><img class="col-6"
                                    :src="Helpers.findArrowImageModule(Helpers.deltaPercentage(engagementMetricData.TotalUniquePlayers , engagementMetricData.TotalUniquePlayersDelta))">
            </div>
            <div
                :class="`${Helpers.setTextColour(Helpers.deltaPercentage(engagementMetricData.TotalUniquePlayers , engagementMetricData.TotalUniquePlayersDelta)) ? 'green-text' : 'red-text' }`">
              {{ Helpers.deltaPercentage(engagementMetricData.TotalUniquePlayers, engagementMetricData.TotalUniquePlayersDelta) }}
            </div>
          </div>
        </div>
        <div class="d-flex flex-column engagement-metric-container p-1 col-3 col-xs-3 col-sm-3 col-md-2 col-lg-3 col-xl-2 m-2">
          <div class="metric-container-header p-1">Questions Served</div>
          <div class="metric-value-text p-1">{{ new Intl.NumberFormat('en-US').format(engagementMetricData.QuestionsServed) }}</div>
          <div class="d-flex flex-row text-center align-items-center pb-1">
            <div class="col-2"><img class="col-6"
                                    :src="Helpers.findArrowImageModule(Helpers.deltaPercentage(engagementMetricData.QuestionsServed , engagementMetricData.QuestionsServedHistoricDelta))">
            </div>
            <div
                :class="`${Helpers.setTextColour(Helpers.deltaPercentage(engagementMetricData.QuestionsServed , engagementMetricData.QuestionsServedHistoricDelta)) ? 'green-text' : 'red-text' }`">
              {{ Helpers.deltaPercentage(engagementMetricData.QuestionsServed, engagementMetricData.QuestionsServedHistoricDelta) }}
            </div>
          </div>
        </div>
        <div class="d-flex flex-column engagement-metric-container p-1 col-3 col-xs-3 col-sm-3 col-md-2 col-lg-3 col-xl-2 m-2">
          <div class="metric-container-header p-1">Prize Winners</div>
          <div class="metric-value-text p-1">{{ new Intl.NumberFormat('en-US').format(engagementMetricData.PlayersAwarded) }}</div>
        </div>
        <div class="d-flex flex-column engagement-metric-container p-1 col-3 col-xs-3 col-sm-3 col-md-2 col-lg-3 col-xl-2 m-2">
          <div class="metric-container-header p-1">Upsell Conversions</div>
          <div class="metric-value-text p-1">{{ new Intl.NumberFormat('en-US').format(engagementMetricData.UpsellConversions) }}</div>
          <div class="d-flex flex-row text-center align-items-center pb-1">
            <div class="col-2"><img class="col-6"
                                    :src="Helpers.findArrowImageModule(Helpers.deltaPercentage(engagementMetricData.UpsellConversions , engagementMetricData.TotalUpsellConversionsDelta))">
            </div>
            <div
                :class="`${Helpers.setTextColour(Helpers.deltaPercentage(engagementMetricData.UpsellConversions , engagementMetricData.TotalUpsellConversionsDelta)) ? 'green-text' : 'red-text' }`">
              {{ Helpers.deltaPercentage(engagementMetricData.UpsellConversions, engagementMetricData.TotalUpsellConversionsDelta) }}
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-row justify-content-start m-1">
        <div class="d-flex flex-column engagement-metric-container p-1 col-3 col-xs-3 col-sm-3 col-md-2 col-lg-3 col-xl-2 m-2">
          <div class="metric-container-header p-1">Accessed Once</div>
          <div class="metric-value-text p-1">{{ new Intl.NumberFormat('en-US').format(engagementMetricData.FrequencyVisitedOnce) }}</div>
        </div>
        <div class="d-flex flex-column engagement-metric-container p-1 col-3 col-xs-3 col-sm-3 col-md-2 col-lg-3 col-xl-2 m-2">
          <div class="metric-container-header p-1">Accessed Twice</div>
          <div class="metric-value-text p-1">{{ new Intl.NumberFormat('en-US').format(engagementMetricData.FrequencyVisitedTwice) }}</div>
        </div>
        <div class="d-flex flex-column engagement-metric-container p-1 col-3 col-xs-3 col-sm-3 col-md-2 col-lg-3 col-xl-2 m-2">
          <div class="metric-container-header p-1">Accessed Thrice</div>
          <div class="metric-value-text p-1">{{ new Intl.NumberFormat('en-US').format(engagementMetricData.FrequencyVisitedThree) }}</div>
        </div>
        <div class="d-flex flex-column engagement-metric-container p-1 col-3 col-xs-3 col-sm-3 col-md-2 col-lg-3 col-xl-2 m-2">
          <div class="metric-container-header p-1">Accessed Four Times</div>
          <div class="metric-value-text p-1">{{ new Intl.NumberFormat('en-US').format(engagementMetricData.FrequencyVisitedFour) }}</div>
        </div>
      </div>
      <div class="d-flex flex-column align-items-center col-12 mt-2 mb-4">
        <LineChart :dailyFiveEngagement="dailyFiveEngagement.dailyFiveGraphStats"></LineChart>
      </div>
    </div>
    <div class="d-flex flex-column align-items-center mt-2 mb-2 col-12">
      <div class="col-11">
        <table class="table table-hover" id="tblToExcl">
          <thead>
          <tr>
            <th class="col-1" scope="col"></th>
            <th class="col-3" scope="col">Date</th>
            <th class="col-2">Unique Players</th>
            <th class="col-2">Questions Served</th>
            <th class="col-2">Players Awarded</th>
            <th class="col-2">Upsell Conversions</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(day, index) in engagementMetricsTable.slice().reverse()" :key=index>
            <td>{{ index + 1 + '.' }}</td>
            <td> {{ Helpers.dateConversion(day.Date.slice(0, 10)) }}</td>
            <td>{{ new Intl.NumberFormat('en-US').format(day.UniquePlayers) }}</td>
            <td>{{ new Intl.NumberFormat('en-US').format(day.QuestionsServed) }}</td>
            <td>{{ new Intl.NumberFormat('en-US').format(day.PlayersAwardedDaily) }}</td>
            <td>{{ day.UpsellConversions || 0 }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <button class="d-flex flex-column justify-content-center export-button p-2" @click="htmlTableToExcel()">Export to Excel</button>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {mapMutations, mapState} from "vuex";
import flatpickr from "flatpickr";
import DashboardService from "@/services/dashboardService";
import LineChart from "@/views/components/charts/LineChart";
import * as XLSX from 'xlsx/xlsx.mjs';

export default {
  name: "DailyFiveEngagement",
  inheritAttrs: false,
  props: ['dailyFiveEngagement'],
  computed: {
    ...mapState(['productConfig', 'startingDateCalendar', 'endingDateCalendar'])
  },
  components: {
    LineChart
  },
  data() {
    return {
      dateRangePicked: `${moment().subtract(7, 'd').format('YYYY-MM-DD')} to ${moment().subtract(1, 'd').format('YYYY-MM-DD')}`,
      startingDatePicked: undefined,
      endDatePicked: undefined,
      engagementMetricData: [],
      engagementMetricsTable: [],
    }
  },
  methods: {
    ...mapMutations(['setTriggerNewChart', 'setLineChartData', 'setLineChartToLoad', 'setStartingDateCalendar', 'setEndingDateCalendar']),
    htmlTableToExcel() {
      let table_elt = document.getElementById("tblToExcl");
      let workbook = XLSX.utils.table_to_book(table_elt);
      XLSX.writeFile(workbook, `Daily 5 Engagement Report.xlsb`);
    },
    async confirmDateRangePicked(dateRange) {
      this.sliceDateRange(dateRange);
      await this.setNewEngagementMetrics();
      this.setTriggerNewChart(true)
      this.setLineChartData(this.updatedEngagementMetricsData.dailyFiveGraphStats)
    },
    sliceDateRange(dateRange) {
      this.startingDatePicked = dateRange.slice(0, 10)
      this.endDatePicked = dateRange.substr(-10)
      this.setStartingDateCalendar(this.startingDatePicked)
      this.setEndingDateCalendar(this.endDatePicked)
    },
    setUpdatedDateRange() {
      this.dateRangePicked = `${this.startingDateCalendar} to ${this.endingDateCalendar}`
      this.startingDatePicked = this.startingDateCalendar
      this.endDatePicked = this.endingDateCalendar
    },
    async setNewEngagementMetrics() {
      let getDailyFiveEngagementMetricsResponse = await DashboardService.getEngagementStats(`${this.startingDatePicked}`, `${this.endDatePicked}`, this.productConfig.productId)
      this.updatedEngagementMetricsData = getDailyFiveEngagementMetricsResponse.data
      this.engagementMetricData = this.updatedEngagementMetricsData
      this.engagementMetricsTable = this.updatedEngagementMetricsData.dailyFiveGraphStats
    },
    triggerUpdatedChart() {
      this.setTriggerNewChart(true)
      this.setLineChartData(this.updatedEngagementMetricsData.dailyFiveGraphStats)
    },
    setDefaultEngagementMetrics() {
      this.engagementMetricData = this.dailyFiveEngagement
      this.engagementMetricsTable = this.dailyFiveEngagement.dailyFiveGraphStats
    },
    async checkDateRangeChanged() {
      if (this.startingDateCalendar !== undefined) {
        this.setUpdatedDateRange()
        await this.setNewEngagementMetrics()
        this.triggerUpdatedChart()
      } else {
        this.setDefaultEngagementMetrics()
      }
    },
  },
  mounted() {
    flatpickr('#datepicker', {
      mode: "range"
    })
  },
  async beforeMount() {
    await this.checkDateRangeChanged()
  }
}
</script>

<style scoped>
.overview-button, .confirm-button, .export-button {
  height: 35px;
  border-radius: 18px;
  font-weight: 600;
  font-size: 20px;
  border: 0;
  background-color: #EE5C36;
  color: #FFFFFF;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

input {
  text-align: center;
}

.engagement-header {
  font-size: 20px;
  font-weight: 600;
}

.engagement-metric-container {
  border-radius: 12px;
  font-weight: 600;
  border: 0;
  background-color: #F4D1CE;
  color: #000000;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.metric-container-header, .red-text, .green-text {
  font-size: 18px;
}

.metric-value-text {
  font-size: 30px;
}

.red-text {
  color: #ff0000;
}

.green-text {
  color: #207520;
}


@media only screen and (max-width: 576px) {
  .metric-container-header, .red-text, .green-text {
    font-size: 12px;
  }

  .export-button {
    height: 28px;
    font-size: 15px;
  }

  .metric-value-text {
    font-size: 12px;
  }

  table {
    margin-top: 20px;
    font-size: 10.5px;
  }

  .overview-button, .confirm-button {
    height: 28px;
    font-size: 18px;
  }

  .engagement-metric-container {
    width: 22% !important;
  }

  input {
    font-size: 10.5px;
  }
}
</style>
