import moment from "moment";

export default class Helpers {
    static deltaPercentage(newValue, oldValue) {
        const result = (((newValue - oldValue) / oldValue) * 100);
        return isNaN(result) || result === Infinity ? 'N/A' : result.toFixed(2) + '%';
    }

    static setTextColour(numberPercentage) {
        numberPercentage = numberPercentage.replace(/%/g, '');
        return numberPercentage > 0;
    }

    static findArrowImageModule(numberPercentage) {
        if (numberPercentage === "N/A") {
            return [require(`@/assets/neutral-arrow-icon.png`)];
        }
        const parsedNumber = parseFloat(numberPercentage);
        const arrowImage = parsedNumber < 0 ? require(`@/assets/down-arrow-icon.png`) : require(`@/assets/up-arrow-icon.png`);
        return [arrowImage];
    }

    static dateConversion(date, monthly) {
        const format = (monthly === 1) ? "MMMM YYYY" : "D MMMM YYYY";
        return moment(date.slice(0, 10)).format(format);
    }
}
