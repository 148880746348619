import moment from "moment";

export function loadLineChartData(chartData, chartLabel, productName) {

    if (chartLabel[0] === 'Unique Users') {
        let destructuredChartDataArrayResponse = destructureChartDataArray(chartData, chartLabel)
        return {
            type: "line",
            data: {
                labels: destructuredChartDataArrayResponse[1],
                datasets: [
                    {
                        label: chartLabel[1],
                        data: destructuredChartDataArrayResponse[0].TotalEngagedArray,
                        fill: false,
                        backgroundColor: '#000000',
                        borderColor: '#000000',
                        tension: 0.1
                    },
                    {
                        label: chartLabel[0],
                        data: destructuredChartDataArrayResponse[0].UniqueEngagedArray,
                        fill: false,
                        backgroundColor: '#f5c5c3',
                        borderColor: '#f5c5c3',
                        tension: 0.1
                    },
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                        ticks: {
                            callback: function (value) {
                                return value / 1000 + 'K'
                            },
                            beginAtZero: true
                        }
                    }]
                }
            }
        }
    }

    if (chartLabel[0] === 'MTN MzansiGames') {
        let destructuredChartDataArrayResponse = destructureChartDataArray(chartData, chartLabel)
        const datasetsGraphConfig = getDataSetsGraphConfig(destructuredChartDataArrayResponse.datasets)
        return {
            type: "line",
            data: {
                labels: destructuredChartDataArrayResponse.labelsArray,
                datasets: datasetsGraphConfig
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                        ticks: {
                            callback: function (value) {
                                return value
                            },
                            beginAtZero: true
                        }
                    }]
                }
            }
        }
    }

    if (chartLabel[0] === 'Entries' && productName === 'mini-app') {
        let destructuredChartDataArrayResponse = destructureChartDataArray(chartData, chartLabel, productName)
        return {
            type: "line",
            data: {
                labels: destructuredChartDataArrayResponse[1],
                datasets: [
                    {
                        label: chartLabel[0],
                        data: destructuredChartDataArrayResponse[0].EntriesArray,
                        fill: false,
                        backgroundColor: '#000000',
                        borderColor: '#000000',
                        tension: 0.1
                    },
                    {
                        label: chartLabel[1],
                        data: destructuredChartDataArrayResponse[0].SubscriptionsArray,
                        fill: false,
                        backgroundColor: '#f5c5c3',
                        borderColor: '#f5c5c3',
                        tension: 0.1
                    },
                    {
                        label: chartLabel[2],
                        data: destructuredChartDataArrayResponse[0].VisitsArray,
                        fill: false,
                        backgroundColor: "#EE5C36",
                        borderColor: "#EE5C36",
                        tension: 0.1
                    },
                    {
                        label: chartLabel[3],
                        data: destructuredChartDataArrayResponse[0].VisitorsArray,
                        fill: false,
                        backgroundColor: "#ff0303",
                        borderColor: "#ff0303",
                        tension: 0.1
                    },
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                        ticks: {
                            callback: function (value) {
                                return value
                            },
                            beginAtZero: true
                        }
                    }]
                }
            }
        }
    }

    if (chartLabel[0] === 'Entries' && productName !== 'mini-app') {
        let destructuredChartDataArrayResponse = destructureChartDataArray(chartData, chartLabel, productName)
        return {
            type: "line",
            data: {
                labels: destructuredChartDataArrayResponse[1],
                datasets: [
                    {
                        label: chartLabel[0],
                        data: destructuredChartDataArrayResponse[0].EntriesArray,
                        fill: false,
                        backgroundColor: '#000000',
                        borderColor: '#000000',
                        tension: 0.1
                    },
                    {
                        label: chartLabel[2],
                        data: destructuredChartDataArrayResponse[0].VisitsArray,
                        fill: false,
                        backgroundColor: "#EE5C36",
                        borderColor: "#EE5C36",
                        tension: 0.1
                    },
                    {
                        label: chartLabel[3],
                        data: destructuredChartDataArrayResponse[0].VisitorsArray,
                        fill: false,
                        backgroundColor: "#ff0303",
                        borderColor: "#ff0303",
                        tension: 0.1
                    },
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                        ticks: {
                            callback: function (value) {
                                return value
                            },
                            beginAtZero: true
                        }
                    }]
                }
            }
        }
    }

    if (chartLabel[0] === 'Unique Players') {
        let destructuredChartDataArrayResponse = destructureChartDataArray(chartData, chartLabel)
        return {
            type: "line",
            data: {
                labels: destructuredChartDataArrayResponse[1],
                datasets: [
                    {
                        label: chartLabel[0],
                        data: destructuredChartDataArrayResponse[0].UniquePlayersArray,
                        fill: false,
                        backgroundColor: '#f5c5c3',
                        borderColor: '#f5c5c3',
                        tension: 0.1
                    },
                    {
                        label: chartLabel[1],
                        data: destructuredChartDataArrayResponse[0].QuestionsServedArray,
                        fill: false,
                        backgroundColor: '#000000',
                        borderColor: '#000000',
                        tension: 0.1
                    },
                    {
                        label: chartLabel[2],
                        data: destructuredChartDataArrayResponse[0].PlayersAwardedDailyArray,
                        fill: false,
                        backgroundColor: "#EE5C36",
                        borderColor: "#EE5C36",
                        tension: 0.1
                    },
                    {
                        label: chartLabel[3],
                        data: destructuredChartDataArrayResponse[0].UpsellConversionsArray,
                        fill: false,
                        backgroundColor: "#0d57a7",
                        borderColor: "#0d57a7",
                        tension: 0.1
                    },
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                        ticks: {
                            callback: function (value) {
                                return value
                            },
                            beginAtZero: true
                        }
                    }]
                }
            }
        }
    }

    if (chartLabel[0] === 'Subscribed') {
        let destructuredChartDataArrayResponse = destructureChartDataArray(chartData, chartLabel)
        return {
            type: "line",
            data: {
                labels: destructuredChartDataArrayResponse[1],
                datasets: [
                    {
                        label: chartLabel[0],
                        data: destructuredChartDataArrayResponse[0].TotalSubscribedUsersArray,
                        fill: false,
                        backgroundColor: '#000000',
                        borderColor: '#000000',
                        tension: 0.1
                    },
                    {
                        label: chartLabel[1],
                        data: destructuredChartDataArrayResponse[0].TotalCancelledUsersArray,
                        fill: false,
                        backgroundColor: "#EE5C36",
                        borderColor: "#EE5C36",
                        tension: 0.1
                    },
                    {
                        label: chartLabel[3],
                        data: destructuredChartDataArrayResponse[0].OrganicUSSDArray,
                        fill: false,
                        backgroundColor: '#019301',
                        borderColor: '#019301',
                        tension: 0.1
                    },
                    {
                        label: chartLabel[2],
                        data: destructuredChartDataArrayResponse[0].OrganicWebArray,
                        fill: false,
                        backgroundColor: "#0d85de",
                        borderColor: "#0d85de",
                        tension: 0.1
                    },
                    {
                        label: chartLabel[4],
                        data: destructuredChartDataArrayResponse[0].SimpushArray,
                        fill: false,
                        backgroundColor: '#ffcc00',
                        borderColor: '#FFCC00FF',
                        tension: 0.1
                    },
                    {
                        label: chartLabel[5],
                        data: destructuredChartDataArrayResponse[0].GlobalUnsubsArray,
                        fill: false,
                        backgroundColor: '#7B0EA8FF',
                        borderColor: '#7B0EA8FF',
                        tension: 0.1
                    },
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                        ticks: {
                            callback: function (value) {
                                return value
                            },
                            beginAtZero: true
                        }
                    }]
                }
            }
        }
    }

    if (chartLabel[0] === 'Opening Base') {
        let destructuredChartDataArrayResponse = destructureChartDataArray(chartData, chartLabel)
        return {
            type: "line",
            data: {
                labels: destructuredChartDataArrayResponse[1],
                datasets: [
                    {
                        label: chartLabel[2],
                        data: destructuredChartDataArrayResponse[0].TotalSubscribedUsersArray,
                        fill: false,
                        backgroundColor: '#0f850f',
                        borderColor: '#0f850f',
                        tension: 0.1
                    },
                    {
                        label: chartLabel[7],
                        data: destructuredChartDataArrayResponse[0].TotalCancelledUsersArray,
                        fill: false,
                        backgroundColor: '#800080',
                        borderColor: '#800080',
                        tension: 0.1
                    },
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                        ticks: {
                            callback: function (value) {
                                return value
                            },
                            beginAtZero: true
                        }
                    }]
                }
            }
        }
    }
}


function getDataSetsGraphConfig(datasets) {
    const datasetsGraphConfig = [];
    datasets.forEach((dataset, productName) => {
        let hexColour = Math.floor(Math.random() * 16777215).toString(16)
        datasetsGraphConfig.push({
            label: productName,
            data: dataset,
            fill: false,
            backgroundColor: `#${hexColour}`,
            borderColor: `#${hexColour}`,
            tension: 0.1
        });
    })
    return datasetsGraphConfig;
}

function destructureChartDataArray(chartData, chartLabel, productName) {
    if (chartLabel[0] === 'MTN MzansiGames') {
        const datasets = new Map();
        const labelsArray = chartData.map(item => {
            const {ProductName, TotalSpend, Date} = item;
            const dataset = datasets.get(ProductName.replace(/_/g, ' '));
            if (dataset) {
                dataset.push(TotalSpend);
            } else {
                datasets.set(ProductName.replace(/_/g, ' '), [TotalSpend]);
            }
            return moment(Date.slice(0, 10)).format("D MMM YYYY");
        });
        const deducedLabelsArray = [...new Set(labelsArray)];
        return {labelsArray: deducedLabelsArray, datasets};
    }

    if (chartLabel[0] === 'Entries' && productName === 'mini-app') {
        const labelsArray = chartData.map(item => moment(item.Date.slice(0, 10)).format("D MMM YYYY"));
        const propertiesToExtract = [
            'Entries',
            'Subscriptions',
            'Visits',
            'Visitors',
        ];
        return formatChartData(labelsArray, propertiesToExtract, chartData)
    }

    if (chartLabel[0] === 'Entries' && productName !== 'mini-app') {
        const labelsArray = chartData.map(item => moment(item.Date.slice(0, 10)).format("D MMM YYYY"));
        const propertiesToExtract = [
            'Entries',
            'Visits',
            'Visitors',
        ];
        return formatChartData(labelsArray, propertiesToExtract, chartData)
    }


    if (chartLabel[0] === 'Unique Players') {
        const labelsArray = chartData.map(item => moment(item.Date.slice(0, 10)).format("D MMM YYYY"));
        const propertiesToExtract = [
            'UniquePlayers',
            'QuestionsServed',
            'PlayersAwardedDaily',
            'UpsellConversions'
        ];
        return formatChartData(labelsArray, propertiesToExtract, chartData)
    }


    if (chartLabel[0] === 'Subscribed') {
        const labelsArray = chartData.map(item => moment(item.Date.slice(0, 10)).format("D MMM YYYY"));
        const propertiesToExtract = [
            'TotalSubscribedUsers',
            'TotalCancelledUsers',
            'OrganicWeb',
            'OrganicUSSD',
            'Simpush',
            'GlobalUnsubs',
        ];
        return formatChartData(labelsArray, propertiesToExtract, chartData)
    }

    if (chartLabel[0] === 'Opening Base') {
        const labelsArray = chartData.map(item => moment(item.Date.slice(0, 10)).format("D MMM YYYY"));
        const propertiesToExtract = [
            'TotalSubscribedUsers',
            'TotalCancelledUsers',
        ];
        return formatChartData(labelsArray, propertiesToExtract, chartData)
    }
    if (chartLabel[0] === 'Unique Users') {
        const labelsArray = chartData.map(item => moment(item.Date.slice(0, 10)).format("D MMM YYYY"));
        const propertiesToExtract = [
            'TotalEngaged',
            'UniqueEngaged',
        ];
        return formatChartData(labelsArray, propertiesToExtract, chartData)
    }
}

function formatChartData(labelsArray, propertiesToExtract, chartData) {
    const dataArrays = propertiesToExtract.map(property => chartData.map(item => item[property]));
    return [Object.fromEntries(propertiesToExtract.map((property, index) => [`${property}Array`, dataArrays[index]])), labelsArray];
}
