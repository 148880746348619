import axios from "axios";
import {environmentConfig} from "@/config/enviromentConfig";
import moment from "moment";

export default class CampaignHubService {
    static async getVoucherStats() {
        const response = await axios.post(`${environmentConfig.campaignHubService}/get-voucher-stats`, {
                fromDate: '2024-06-15',
                toDate: `${moment().subtract(1, 'd').format('YYYY-MM-DD')}`,
                productId: null,
            },
        )
        return response.data;
    }
}
