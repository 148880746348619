import {productConfigObjectMap} from "@/config/productConfig";

export const productConfigMap = new Map([
    ['/dashboard/stop-the-clock', getProductConfig('stop-the-clock')],
    ['/dashboard/quiz-inc', getProductConfig('quiz-inc')],
    ['/dashboard/quiz-inc-head-2-head', getProductConfig('quiz-inc-head-2-head')],
    ['/dashboard/mini-app', getProductConfig('mini-app')],
    ['/dashboard/unlock-three', getProductConfig('unlock-three')],
    ['/dashboard/mzansi-games', getProductConfig('mzansi-games')],
    ['/dashboard/game-to-win', getProductConfig('game-to-win')],
    ['/dashboard/unlock-more', getProductConfig('unlock-more')],
    ['/dashboard/penrose-awards', getProductConfig('penrose-awards')],
    ['/dashboard/vodacom-fantasy', getProductConfig('vodacom-fantasy')],
    ['/dashboard/mtn-cdl-fantasy', getProductConfig('mtn-cdl-fantasy')],
    ['/dashboard/mtn-cmr-fantasy', getProductConfig('mtn-cmr-fantasy')],
    ['/dashboard/mtn-nga-fantasy', getProductConfig('mtn-nga-fantasy')],
    ['/dashboard/mtn-zmb-fantasy', getProductConfig('mtn-zmb-fantasy')],
    ['/dashboard/eth-et-fantasy', getProductConfig('eth-et-fantasy')],
    ['/dashboard/airtel-nga-fantasy', getProductConfig('airtel-nga-fantasy')],
    ['/dashboard/beat-the-jams', getProductConfig('beat-the-jams')],
    ['/dashboard/freeplay-predictor', getProductConfig('freeplay-predictor')],
    ['/dashboard/quiz-inc-aap', getProductConfig('quiz-inc-aap')],
    ['/dashboard/campaign-ids', getProductConfig('campaign-ids')],
    ['/dashboard/vc-mzansi-games', getProductConfig('vc-mzansi-games')],
    ['/dashboard/master-stream', getProductConfig('master-stream')],
    ['/dashboard/voucher-awards', getProductConfig('voucher-awards')],
    ['/dashboard/mzansi-rhythm', getProductConfig('mzansi-rhythm')],
    ['/dashboard/ais-fantasy', getProductConfig('ais-fantasy')],
    ['/dashboard/ais-mmr', getProductConfig('ais-mmr')],
    ['/dashboard/rugby-zone', getProductConfig('rugby-zone')],
    ['/dashboard/motorsport-zone', getProductConfig('motorsport-zone')],
    ['/dashboard/cricket-zone', getProductConfig('cricket-zone')],
    ['/dashboard/chess-masters', getProductConfig('chess-masters')],
    ['/dashboard/unlock-yello', getProductConfig('unlock-yello')],
]);

export function getProductConfig(product) {
    const productConfigObject = productConfigObjectMap.get(product);
    return {
        product,
        network: productConfigObject.network,
        productId: productConfigObject.productId,
        productTitle: productConfigObject.productTitle,
        initialMetricToDisplay: productConfigObject.initialMetricToDisplay,
        revenueGraph: productConfigObject.revenueGraph,
        lineGraphLabels: productConfigObject.lineGraphLabels,
        engagementGraph: productConfigObject.engagementGraph,
        ussdWebGraph: productConfigObject.ussdWebGraph,
        endpoint: productConfigObject.endpoint,
        predictorLineGraphLabels: productConfigObject.predictorLineGraphLabels,
        dailyFiveLineGraphLabels: productConfigObject.dailyFiveLineGraphLabels,
        awardsGraph: productConfigObject.awardsGraph,
        currencySymbol: productConfigObject.currencySymbol,
        serviceId: productConfigObject.serviceId,
        freePlayServiceId: productConfigObject.freePlayServiceId,
        vipServiceId: productConfigObject.vipServiceId,
        vvipServiceId: productConfigObject.vvipServiceId,
        bannerClicksGraph: productConfigObject.bannerClicksGraph,
        totalPlaysGraph: productConfigObject.totalPlaysGraph,
        campaignIdTitle : productConfigObject.campaignIdTitle,
        weeklyPricePoint : productConfigObject.weeklyPricePoint,
        pricePointServiceIds : productConfigObject.pricePointServiceIds
    };
}
