<template>
  <div class="d-flex flex-column justify-content-center align-items-center col-12 mt-2">
    <div class="col-11">
      <div class="d-flex flex-row col-xl-5 col-11 poppins-regular campaign-tabs">
        <div @click="campaignTabClicked('viewCampaign')"
             :class="`d-flex flex-column align-items-center justify-content-center col-4 ${visibleTab === 'viewCampaign' ? 'campaign-tab-active' : 'campaign-tab-inactive'}`">View Campaign IDs
        </div>
        <div @click="campaignTabClicked('createCampaign')"
             :class="`d-flex flex-column align-items-center justify-content-center col-4 ${visibleTab === 'createCampaign' ? 'campaign-tab-active' : 'campaign-tab-inactive'}`">Create Campaign IDs
        </div>
      </div>
      <div class="scrollable-container">
        <div v-if="visibleTab === 'viewCampaign'">
          <ViewCampaignIDs/>
        </div>
      </div>
      <div class="scrollable-container">
        <div v-if="visibleTab === 'createCampaign'">
          <CreateCampaignIDs/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import ViewCampaignIDs from "@/views/components/campaignIds/ViewCampaignIDs";
import CreateCampaignIDs from "@/views/components/campaignIds/CreateCampaignIDs";

export default {
  name: "CampaignIds",
  components: {ViewCampaignIDs, CreateCampaignIDs},
  data() {
    return {
      visibleTab: "viewCampaign",
    }
  },
  methods: {
    ...mapMutations(['setSidebarMenuVisible']),
    campaignTabClicked(tabRequested) {
      this.visibleTab = tabRequested;
    }
  },
  async beforeMount() {
    this.setSidebarMenuVisible(false);
  },
}
</script>

<style scoped>
.campaign-tab-active {
  border: 1px solid #f1f2f2;
  color: #FFFFFF;
  background-color: #ED5C36;
}

.campaign-tabs {
  position: fixed;
  height: 100px;
}

.scrollable-container {
  position: absolute;
  top: 70px;
  width: 90%;
}

.campaign-tab-inactive {
  border: 1px solid #c5c5c5;
}

.campaign-tab-active, .campaign-tab-inactive {
  height: 50px;
  font-size: 15px;
  margin-right: 8px;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

@media only screen and (max-width: 800px) {
  .campaign-tab-active, .campaign-tab-inactive {
    height: 40px;
    font-size: 11px;
    margin-right: 8px;
  }
}
</style>
