<template>
  <div class="d-flex flex-column">
    <div class="fixed-container">
      <div class="d-flex flex-row poppins-bold col-11 mt-4">
        <div class="filter-header col-1">Campaign ID</div>
        <div @click="toggleFilterScreen('region')" class="d-flex flex-row filter-header col-1">
          <div class="d-flex flex-row">Region</div>
          <div class="d-flex flex-row arrow-img">&#x21e9;</div>
          <div v-if="regionFilterScreen" class="filters-container col-12">
            <div @click="applyFilter('region', region)"
                 class="d-flex flex-row align-items-center col-12 filter-value-container mt-2 mb-2"
                 v-for="(region) in regionFilters">
              <div
                  :class="`${this.filterButtonSelected(this.region, region) ? 'square-selected' : 'square-default'}`"></div>
              <div class="col-10 filter-text">{{ region }}</div>
            </div>
          </div>
        </div>
        <div @click="toggleFilterScreen('network')" class="d-flex flex-row filter-header col-1">
          <div class="d-flex flex-row">Network</div>
          <div class="d-flex flex-row arrow-img">&#x21e9;</div>
          <div v-if="networkFilterScreen" class="filters-container col-12">
            <div @click="applyFilter('network', network)"
                 class="d-flex flex-row align-items-center col-12 filter-value-container mt-2 mb-2"
                 v-for="(network) in networkFilters">
              <div
                  :class="`${this.filterButtonSelected(this.network, network) ? 'square-selected' : 'square-default'}`"></div>
              <div class="col-10 filter-text">{{ network }}</div>
            </div>
          </div>
        </div>
        <div @click="toggleFilterScreen('product')" class="d-flex flex-row filter-header col-2">
          <div class="d-flex flex-row">Product</div>
          <div class="d-flex flex-row arrow-img">&#x21e9;</div>
          <div v-if="productFilterScreen" class="filters-container col-12">
            <div @click="applyFilter('product', product)"
                 class="d-flex flex-row align-items-center col-12 filter-value-container mt-2 mb-2"
                 v-for="(product) in productFilters">
              <div
                  :class="`${this.filterButtonSelected(this.product, product) ? 'square-selected' : 'square-default'}`"></div>
              <div class="col-10 filter-text">{{ product }}</div>
            </div>
          </div>
        </div>
        <div @click="toggleFilterScreen('channelMechanism')" class="d-flex flex-row filter-header col-2">
          <div class="d-flex flex-row">Channel Mechanism</div>
          <div class="d-flex flex-row arrow-img">&#x21e9;</div>
          <div v-if="channelMechanismFilterScreen" class="filters-container col-12">
            <div @click="applyFilter('channelMechanism', channelMechanism)"
                 class="d-flex flex-row align-items-center col-12 filter-value-container mt-2 mb-2"
                 v-for="(channelMechanism) in channelMechanisms">
              <div
                  :class="`${this.filterButtonSelected(this.channelMechanism, channelMechanism) ? 'square-selected' : 'square-default'}`"></div>
              <div class="col-10 filter-text">{{ channelMechanism }}</div>
            </div>
          </div>
        </div>
        <div @click="toggleFilterScreen('marketingPartner')" class="d-flex flex-row filter-header col-2">
          <div class="d-flex flex-row">Marketing Partner</div>
          <div class="d-flex flex-row arrow-img">&#x21e9;</div>
          <div v-if="marketingPartnerFilterScreen" class="filters-container col-12">
            <div @click="applyFilter('marketingPartner', marketingPartner)"
                 class="d-flex flex-row align-items-center col-12 filter-value-container mt-2 mb-2"
                 v-for="(marketingPartner) in marketingPartners">
              <div
                  :class="`${this.filterButtonSelected(this.marketingPartner, marketingPartner) ? 'square-selected' : 'square-default'}`"></div>
              <div class="col-10 filter-text">{{ marketingPartner }}</div>
            </div>
          </div>
        </div>
        <div class="filter-header-description col-3">Custom Description</div>
      </div>
      <div class="d-flex flex-row poppins-bold col-11 mt-3">
        <div class="d-flex flex-column justify-content-center filter-row col-1"></div>
        <div class="d-flex flex-column justify-content-center filter-row col-1">{{ this.filterNullText(region) }}</div>
        <div class="d-flex flex-column justify-content-center filter-row col-1">{{ this.filterNullText(network) }}</div>
        <div class="d-flex flex-column justify-content-center filter-row col-2">{{ this.filterNullText(product) }}</div>
        <div class="d-flex flex-column justify-content-center filter-row col-2">{{
            this.filterNullText(channelMechanism)
          }}
        </div>
        <div class="d-flex flex-column justify-content-center filter-row col-2">{{
            this.filterNullText(marketingPartner)
          }}
        </div>
        <div class="d-flex flex-column justify-content-center filter-row-description col-3 fst-italic">All</div>
      </div>
      <div class="poppins-regular mt-3 mb-2"><span class="fst-italic">Campaign Count:</span> <span
          class="fw-bold">{{ campaignIds.length }}</span></div>
    </div>
  </div>
  <div class="d-flex flex-column table-container">
    <div v-if="loadCampaigns === true"
         class="d-flex flex-column align-items-center justify-content-center spinner-container col-11">
      <div class="spinner-border" role="status">
        <span class="sr-only"></span>
      </div>
    </div>
    <div v-if="campaignIds.length !== 0" v-for="(campaign, index) in campaignIds" :key=index
         class="d-flex flex-row poppins-regular col-11 mt-2">
      <div class="d-flex flex-column justify-content-center table-row col-1">{{ campaign.CampaignId }}</div>
      <div class="d-flex flex-column justify-content-center table-row col-1">{{ campaign.Region }}</div>
      <div class="d-flex flex-column justify-content-center table-row col-1">{{ campaign.Network }}</div>
      <div class="d-flex flex-column justify-content-center table-row col-2">{{ campaign.Product }}</div>
      <div class="d-flex flex-column justify-content-center table-row col-2">{{ campaign.Channel_Mechanism }}</div>
      <div class="d-flex flex-column justify-content-center table-row col-2">{{
          campaign.MarketingPartner_Provider
        }}
      </div>
      <div class="d-flex flex-column justify-content-center table-row-description col-3 fst-italic">
        {{ this.truncate(campaign.CustomDescription, 48) }}
      </div>
    </div>
    <div v-if="campaignIds.length === 0" class="d-flex flex-column align-items-center col-11">
      <img class="col-2 mt-3" src="../../../assets/no-campaign-found-icon.png">
      <div class="text-center mt-2 col-5">We couldn't find any campaign IDs that match you criteria. Please try creating
        a new campaign or adjusting your search parameters
      </div>
    </div>
  </div>
</template>

<script>
import DashboardService from "@/services/dashboardService";

export default {
  name: "ViewCampaignIDs",
  data() {
    return {
      campaignCount: undefined,
      campaignIds: [],
      loadCampaigns: undefined,
      region: null,
      network: null,
      product: null,
      channelMechanism: null,
      marketingPartner: null,
      regionFilterScreen: false,
      regionFilters: {
        null: "All",
        ZA: "ZA",
        Zambia: "Zambia",
      },
      networkFilterScreen: false,
      networkFilters: undefined,
      productFilterScreen: false,
      productFilters: undefined,
      channelMechanismFilterScreen: false,
      channelMechanisms: undefined,
      marketingPartnerFilterScreen: false,
      marketingPartners: undefined,
    }
  },
  methods: {
    truncate(text, characterCount) {
      if (!text) return '';

      return text.length > characterCount
          ? `${text.substring(0, characterCount)}...`
          : text;
    },
    filterNullText(text) {
      return text === null ? 'All' : text;
    },
    filterButtonSelected(filterOption, filterSelected) {
      return filterOption === null && filterSelected === 'All' || filterSelected === filterOption;
    },
    toggleFilterScreen(filterType) {
      this[filterType + 'FilterScreen'] = !this[filterType + 'FilterScreen'];
    },
    async applyFilter(filterType, filterValue) {
      this.loadCampaigns = true;
      this[filterType + 'FilterScreen'] === false;
      this.campaignIds = [];
      this[filterType] = filterValue === 'All' ? null : filterValue;
      this.campaignIds = (await DashboardService.getCampaignsByFilters(this.region, this.network, this.product, this.channelMechanism, this.marketingPartner, null)).data[0];
      this.campaignIds.sort((a, b) => a.CampaignId - b.CampaignId);
      this.loadCampaigns = false;
    },
    async configureCampaignDropDowns() {
      const response = await DashboardService.getCampaignsByDropDowns();
      this.networkFilters = response[0].NetworkNameFilters;
      this.productFilters = response[1].productToFilters;
      this.channelMechanisms = response[2].Channel_MechanismFilters;
      this.marketingPartners = response[3].MarketingPartner_ProviderFilters;
    }
  },
  async beforeMount() {
    this.loadCampaigns = true;
    this.campaignIds = (await DashboardService.getCampaignsByFilters()).data[0];
    this.campaignIds.sort((a, b) => a.CampaignId - b.CampaignId);
    await this.configureCampaignDropDowns();
    this.loadCampaigns = false;
  }
}
</script>

<style scoped>
.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.fixed-container {
  position: absolute;
}

.fixed-container, .table-container {
  width: 95vw;
}

.table-container {
  position: relative;
  top: 160px;
  max-height: calc(100vh - 300px);
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.table-container::-webkit-scrollbar {
  display: none;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.filter-header, .filter-header-description {
  position: relative;
  cursor: pointer;
  font-size: 15px;
}

.filters-container {
  position: absolute;
  top: 25px;
  background-color: #FFFFFF;
  border-width: thin;
  border-color: #EE5C36;
  border-style: solid;
  font-size: 13px;
  z-index: 1;
}

.filter-row, .filter-row-description {
  border-top: 1px solid #aaaaaa;
  border-bottom: 1px solid #aaaaaa;
  height: 30px;
  font-size: 13px;
  color: #EE5C36;
  font-weight: 700;
}

.table-row, .table-row-description {
  border-top: 1px solid #aaaaaa;
  height: 34px;
  font-size: 13px;
}

.spinner-container {
  height: 400px;
}

.filter-value-container {
  height: 25px;
  margin-left: 5px;
  font-weight: 500;
}

.square-selected, .square-default {
  border: 1px solid #aaaaaa;
  width: 13px;
  height: 13px;
}

.square-selected {
  background-color: #EE5C36;
}

.filter-text {
  padding-left: 5px;
}

.arrow-img {
  margin-left: 5px;
}
</style>
