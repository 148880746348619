<template>
  <div class="d-flex flex-column align-items-center">
    <div class="row align-items-center justify-content-around text-center reporting-metrics-container p-1 col-12">
      <button @click="reportingMetricButtonClicked('productRevenue', 0)"
              :class="`m-1 ${reportingButtonIndex === 0 ? 'reporting-metrics-button-active' : 'reporting-metrics-button-default' }`">
        Revenue
      </button>
      <button @click="reportingMetricButtonClicked('productSubscription', 1)"
              :class="`m-1 ${reportingButtonIndex === 1 ? 'reporting-metrics-button-active' : 'reporting-metrics-button-default' }`">
        Subscription
      </button>
      <button v-if="userData.SMSRights" @click="reportingMetricButtonClicked('engagementCampaign', 2)"
              :class="`m-1 ${reportingButtonIndex === 2 ? 'reporting-metrics-button-active' : 'reporting-metrics-button-default' }`">
        Campaign
      </button>
      <button v-if="userData.SMSRights" @click="reportingMetricButtonClicked('acquisitionCampaign', 3)"
              :class="`m-1 ${reportingButtonIndex === 3 ? 'reporting-metrics-button-active' : 'reporting-metrics-button-default' }`">
        Outreach
      </button>
    </div>
    <div class="d-flex flex-column align-items-center col-12" v-if="this.reportingMetricToShow === 'productRevenue'">
      <ProductRevenue :revenueMetric="revenueMetricsData"></ProductRevenue>
    </div>
    <div class="d-flex flex-column align-items-center col-12"
         v-if="this.reportingMetricToShow === 'productSubscription'">
      <ProductSubscription :subscriptionMetric="subscriptionMetricsData"></ProductSubscription>
    </div>
    <div class="d-flex flex-column align-items-center col-12"
         v-if="this.reportingMetricToShow === 'engagementCampaign' && userData.SMSRights">
      <MzanziGamesCampaign></MzanziGamesCampaign>
    </div>
    <div class="d-flex flex-column align-items-center col-12"
         v-if="this.reportingMetricToShow === 'acquisitionCampaign' && userData.SMSRights">
      <SdpAcquisitionSmsCampaign></SdpAcquisitionSmsCampaign>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import moment from "moment";
import DashboardService from "@/services/dashboardService";
import ProductRevenue from "@/views/components/metrics/ProductRevenue";
import ProductSubscription from "@/views/components/metrics/ProductSubscription";
import MzanziGamesCampaign from "@/views/components/smsCampaign/mtn/engagement/MzanziGamesCampaign";
import SdpAcquisitionSmsCampaign from "@/views/components/smsCampaign/mtn/acquisition/SdpAcquisitionSmsCampaign";

export default {
  name: "MzansiGames",
  components: {ProductRevenue, ProductSubscription, MzanziGamesCampaign, SdpAcquisitionSmsCampaign},
  computed: {
    ...mapState(['productConfig', 'userData'])
  },
  data() {
    return {
      reportingMetricToShow: undefined,
      reportingButtonIndex: undefined,
      todayDate: `${moment().subtract(1, 'd').format('YYYY-MM-DD')}`,
      startingDate: `${moment().subtract(7, 'd').format('YYYY-MM-DD')}`,
      revenueMetricsData: [],
      subscriptionMetricsData: [],
    }
  },
  methods: {
    ...mapMutations(['setReportingMetric', 'setSidebarMenuVisible']),
    setReportingMetricToShow() {
      this.reportingMetricToShow = this.productConfig.initialMetricToDisplay;
      this.setReportingMetric(this.reportingMetricToShow);
      this.reportingButtonIndex = 0;
    },
    async reportingMetricButtonClicked(metricType, index) {
      await this.loadProductData(metricType);
      this.reportingMetricToShow = metricType;
      this.reportingButtonIndex = index;
      this.setReportingMetric(this.reportingMetricToShow);
    },
    async getRevenueDataMetrics() {
      this.revenueMetricsData = (await DashboardService.getRevenueStats(`${this.startingDate}`, `${this.todayDate}`, this.productConfig.productId, null, null, 0)).data;
    },
    async getSubscriptionDataMetrics() {
      this.subscriptionMetricsData = (await DashboardService.getSubscriptionStats(`${this.startingDate}`, `${this.todayDate}`, this.productConfig.productId, null, undefined, 0)).data;
    },
    async loadProductData(metricType) {
      const metricFunctions = {
        'productRevenue': this.getRevenueDataMetrics,
        'productSubscription': this.getSubscriptionDataMetrics,
      };
      const selectedFunction = metricFunctions[metricType];
      if (selectedFunction) await selectedFunction.call(this);
    },
    checkUserAccessRights() {
      return (this.userData.MTN);
    }
  },
  async beforeMount() {
    const userHasAccess = this.checkUserAccessRights();
    if (userHasAccess) {
      this.setSidebarMenuVisible(false);
      await this.loadProductData('productRevenue');
      this.setReportingMetricToShow();
    } else {
      this.$router.push(`/login`);
    }
  },
  watch: {
    '$store.state.reportingMetric': function () {
      if (this.$store.state.reportingMetric === 'productRevenue') {
        this.reportingMetricToShow = this.productConfig.initialMetricToDisplay
        this.reportingButtonIndex = 0;
      }
    }
  }
}
</script>

<style scoped>
.reporting-metrics-container {
  background-color: #E6E6E6;
  border-radius: 16px;
}

.reporting-metrics-button-default, .reporting-metrics-button-active {
  border-radius: 20px;
  border: 0;
  height: 40px;
  font-weight: 700;
  font-size: 20px;
}

button {
  width: 200px;
}

.reporting-metrics-button-active {
  background-color: #EE5C36;
  color: #FFFFFF;
}

.reporting-metrics-button-default {
  background-color: #FFFFFF;
  color: #000000;
}

@media only screen and (max-width: 719px) {
  .reporting-metrics-button-default, .reporting-metrics-button-active {
    height: 28px;
    font-size: 17px;
  }
}
</style>
