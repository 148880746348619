import dotenv from 'dotenv';

dotenv.config();

export const productionConfig = {
    dashboardService: `${process.env.VUE_APP_DASHBOARD_SERVICE_URI}`,
    mtnUtilsBitlyUri :`${process.env.VUE_APP_MTN_UTILS_BITLY_URI}`,
    campaignHubService: `${process.env.VUE_APP_CAMPAIGN_HUB_URI}`,
}

export const stagingConfig = {
    dashboardService: `${process.env.VUE_APP_DASHBOARD_SERVICE_URI}`,
    mtnUtilsBitlyUri :`${process.env.VUE_APP_MTN_UTILS_BITLY_URI}`,
    campaignHubService: `${process.env.VUE_APP_CAMPAIGN_HUB_URI}`,
}

const environment = getEnvironment()

export const environmentConfig = getEnvObject();

function getEnvironment() {
    let base_url = window.location.origin;
    if (base_url.includes('staging')) return 'staging'
    if (base_url.includes('localhost')) return 'development'
    else return 'production'
}

function getEnvObject() {
    if (environment === 'staging') return stagingConfig
    if (environment === 'development') return productionConfig
    if (environment === 'production') return productionConfig
}
