import moment from "moment";

export function loadBarChartData(chartData, chartLabel, chartSymbol) {
    if (chartLabel[0] === 'Total Players') {
        let destructuredChartDataArrayResponse = destructureChartDataArray(chartData, chartLabel)
        return {
            type: "bar",
            data: {
                labels: destructuredChartDataArrayResponse[1],
                datasets: [
                    {
                        label: chartLabel[0],
                        data: destructuredChartDataArrayResponse[0].PlayersArray,
                        backgroundColor: "#EE5C36",
                        borderColor: "#EE5C36",
                        borderWidth: 1
                    },
                    {
                        label: chartLabel[1],
                        data: destructuredChartDataArrayResponse[0].TotalInteractionsArray,
                        backgroundColor: "#000000",
                        borderColor: "#000000FF",
                        borderWidth: 1
                    },
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                        ticks: {
                            callback: function (value) {
                                return (value);
                            },
                            beginAtZero: true
                        }
                    }]
                }
            }
        }
    }

    if (chartLabel[0] === 'Total Engaged') {
        let destructuredChartDataArrayResponse = destructureChartDataArray(chartData, chartLabel)
        return {
            type: "bar",
            data: {
                labels: destructuredChartDataArrayResponse[1],
                datasets: [
                    {
                        label: chartLabel[0],
                        data: destructuredChartDataArrayResponse[0].TotalEngagedArray,
                        backgroundColor: "#EE5C36",
                        borderColor: "#EE5C36",
                        borderWidth: 1
                    },
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                        ticks: {
                            callback: function (value) {
                                return (value);
                            },
                            beginAtZero: true
                        }
                    }]
                }
            }
        }
    }

    if (chartLabel[0] === 'Banner Clicks') {
        let destructuredChartDataArrayResponse = destructureChartDataArray(chartData, chartLabel)
        return {
            type: "bar",
            data: {
                labels: destructuredChartDataArrayResponse[1],
                datasets: [
                    {
                        label: chartLabel[0],
                        data: destructuredChartDataArrayResponse[0].TotalClicksArray,
                        backgroundColor: "#EE5C36",
                        borderColor: "#EE5C36",
                        borderWidth: 1
                    },
                    {
                        label: chartLabel[1],
                        data: destructuredChartDataArrayResponse[0].UniqueClicksArray,
                        backgroundColor: "#000000",
                        borderColor: "#000000FF",
                        borderWidth: 1
                    },
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                        ticks: {
                            callback: function (value) {
                                return (value);
                            },
                            beginAtZero: true
                        }
                    }]
                }
            }
        }
    }

    if (chartLabel === 'Gross Revenues' && chartSymbol !== '$') {
        let destructuredChartDataArrayResponse = destructureChartDataArray(chartData, chartLabel)
        return {
            type: "bar",
            data: {
                labels: destructuredChartDataArrayResponse[1],
                datasets: [
                    {
                        label: chartLabel,
                        data: destructuredChartDataArrayResponse[0].SuccessfullyBilledAmountArray,
                        backgroundColor: "#EE5C36",
                        borderColor: "#EE5C36",
                        borderWidth: 1
                    },
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                        ticks: {
                            callback: function (value) {
                                return ('R' + value);
                            },
                            beginAtZero: true
                        }
                    }]
                }
            }
        }
    }

    if (chartLabel === 'Gross Revenues' && chartSymbol === '$') {
        let destructuredChartDataArrayResponse = destructureChartDataArray(chartData, chartLabel)
        return {
            type: "bar",
            data: {
                labels: destructuredChartDataArrayResponse[1],
                datasets: [
                    {
                        label: chartLabel,
                        data: destructuredChartDataArrayResponse[0].SuccessfullyBilledAmountUSDArray,
                        backgroundColor: "#EE5C36",
                        borderColor: "#EE5C36",
                        borderWidth: 1
                    },
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                        ticks: {
                            callback: function (value) {
                                return (chartSymbol + value);
                            },
                            beginAtZero: true
                        }
                    }]
                }
            }
        }
    }

    if (chartLabel === 'Unique Players') {
        let destructuredChartDataArrayResponse = destructureChartDataArray(chartData, chartLabel)
        return {
            type: "bar",
            data: {
                labels: destructuredChartDataArrayResponse[1],
                datasets: [
                    {
                        label: chartLabel,
                        data: destructuredChartDataArrayResponse[0].UniqueEngagedArray,
                        backgroundColor: "#EE5C36",
                        borderColor: "#EE5C36",
                        borderWidth: 1
                    },
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                        ticks: {
                            callback: function (value) {
                                return (value);
                            },
                            beginAtZero: true
                        }
                    }]
                }
            }
        }
    }

    if (chartLabel[1] === 'Video Views') {
        let destructuredChartDataArrayResponse = destructureChartDataArray(chartData, chartLabel)
        return {
            type: "bar",
            data: {
                labels: destructuredChartDataArrayResponse[1],
                datasets: [
                    {
                        label: chartLabel[0],
                        data: destructuredChartDataArrayResponse[0].UniqueLoginsArray,
                        backgroundColor: "#EE5C36",
                        borderColor: "#EE5C36",
                        borderWidth: 1
                    },
                    {
                        label: chartLabel[1],
                        data: destructuredChartDataArrayResponse[0].UniqueViewsArray,
                        backgroundColor: "#000000",
                        borderColor: "#000000",
                        borderWidth: 1
                    },
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                        ticks: {
                            callback: function (value) {
                                return (value);
                            },
                            beginAtZero: true
                        }
                    }]
                }
            }
        }
    }

    if (chartLabel === 'Unique Logins') {
        let destructuredChartDataArrayResponse = destructureChartDataArray(chartData, chartLabel)
        return {
            type: "bar",
            data: {
                labels: destructuredChartDataArrayResponse[1],
                datasets: [
                    {
                        label: chartLabel,
                        data: destructuredChartDataArrayResponse[0].UniqueLoginsArray,
                        backgroundColor: "#EE5C36",
                        borderColor: "#EE5C36",
                        borderWidth: 1
                    },
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                        ticks: {
                            callback: function (value) {
                                return (value);
                            },
                            beginAtZero: true
                        }
                    }]
                }
            }
        }
    }

    if (chartLabel[0] === 'Unique Players USSD') {
        let destructuredChartDataArrayResponse = destructureChartDataArray(chartData, chartLabel)
        return {
            type: "bar",
            data: {
                labels: destructuredChartDataArrayResponse[1],
                datasets: [
                    {
                        label: chartLabel[0],
                        data: destructuredChartDataArrayResponse[0].ussd_unique_player_engagementArray,
                        backgroundColor: "#EE5C36",
                        borderColor: "#EE5C36",
                        borderWidth: 1
                    },
                    {
                        label: chartLabel[1],
                        data: destructuredChartDataArrayResponse[0].web_unique_player_engagementArray,
                        backgroundColor: "#000000",
                        borderColor: "#000000FF",
                        borderWidth: 1
                    },
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                        ticks: {
                            callback: function (value) {
                                return (value);
                            },
                            beginAtZero: true
                        }
                    }]
                }
            }
        }
    }
}

function destructureChartDataArray(chartData, chartLabel) {
    if (chartLabel === 'Gross Revenues') {
        const labelsArray = chartData.map(item => moment(item.Date.slice(0, 10)).format("D MMM YYYY"));
        const propertiesToExtract = [
            'SuccessfullyBilledAmount',
            'SuccessfullyBilledAmountUSD',
        ];
        const dataArrays = propertiesToExtract.map(property => chartData.map(item => item[property]));
        return [Object.fromEntries(propertiesToExtract.map((property, index) => [`${property}Array`, dataArrays[index]])), labelsArray];
    }

    if (chartLabel === 'Unique Players') {
        const labelsArray = chartData.map(item => moment(item.Date.slice(0, 10)).format("D MMM YYYY"));
        const propertiesToExtract = [
            'UniqueEngaged',
        ];
        const dataArrays = propertiesToExtract.map(property => chartData.map(item => item[property]));
        return [Object.fromEntries(propertiesToExtract.map((property, index) => [`${property}Array`, dataArrays[index]])), labelsArray];
    }

    if (chartLabel[0] === 'Unique Players USSD') {
        const labelsArray = chartData.map(item => moment(item.Date.slice(0, 10)).format("D MMM YYYY"));
        const propertiesToExtract = [
            'ussd_unique_player_engagement',
            'web_unique_player_engagement'
        ];
        const dataArrays = propertiesToExtract.map(property => chartData.map(item => item[property]));
        return [Object.fromEntries(propertiesToExtract.map((property, index) => [`${property}Array`, dataArrays[index]])), labelsArray];
    }

    if (chartLabel[0] === 'Banner Clicks') {
        const labelsArray = chartData.map(item => moment(item.Date.slice(0, 10)).format("D MMM YYYY"));
        const propertiesToExtract = [
            'TotalClicks',
            'UniqueClicks'
        ];
        const dataArrays = propertiesToExtract.map(property => chartData.map(item => item[property]));
        return [Object.fromEntries(propertiesToExtract.map((property, index) => [`${property}Array`, dataArrays[index]])), labelsArray];
    }

    if (chartLabel[0] === 'Total Engaged') {
        const labelsArray = chartData.map(item => moment(item.Date).format('LT'));
        const propertiesToExtract = [
            'TotalEngaged',
        ];
        const dataArrays = propertiesToExtract.map(property => chartData.map(item => item[property]));
        return [Object.fromEntries(propertiesToExtract.map((property, index) => [`${property}Array`, dataArrays[index]])), labelsArray];
    }

    if (chartLabel[0] === 'Total Players') {
        const labelsArray = chartData.map(item => moment(item.Date.slice(0, 10)).format("D MMM YYYY"));
        const propertiesToExtract = [
            'Players',
            'TotalInteractions'
        ];
        const dataArrays = propertiesToExtract.map(property => chartData.map(item => item[property]));
        return [Object.fromEntries(propertiesToExtract.map((property, index) => [`${property}Array`, dataArrays[index]])), labelsArray];
    }

    if (chartLabel === 'Unique Logins') {
        const labelsArray = chartData.map(item => moment(item.Date.slice(0, 10)).format("D MMM YYYY"));
        const propertiesToExtract = [
            'UniqueLogins',
        ];
        const dataArrays = propertiesToExtract.map(property => chartData.map(item => item[property]));
        return [Object.fromEntries(propertiesToExtract.map((property, index) => [`${property}Array`, dataArrays[index]])), labelsArray];
    }

    if (chartLabel[1] === 'Video Views') {
        const labelsArray = chartData.map(item => moment(item.Date.slice(0, 10)).format("D MMM YYYY"));
        const propertiesToExtract = [
            'UniqueLogins',
            'UniqueViews',
        ];
        const dataArrays = propertiesToExtract.map(property => chartData.map(item => item[property]));
        return [Object.fromEntries(propertiesToExtract.map((property, index) => [`${property}Array`, dataArrays[index]])), labelsArray];
    }
}
