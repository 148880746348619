<template>
  <div class="d-flex flex-column justify-content-center align-items-center col-12 mt-3">
    <div class="d-flex flex-row justify-content-around col-12">
      <button class="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-2 overview-button">Overview</button>
      <input v-model="dateRangePicked" class="col-4 col-md-4 col-sm-5 col-xl-3" placeholder="Select Date..." type="text" id="datepicker" autocomplete="off"/>
      <button @click="confirmDateRangePicked(dateRangePicked)" class="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-2 confirm-button">Confirm</button>
    </div>
  </div>
  <div class="d-flex flex-column align-items-center col-12 mt-2 mb-4">
    <LineChart :engagementMetric="engagementMetric.dateDailyBreakdown"></LineChart>
  </div>
  <div class="d-flex flex-column col-11">
    <div class="service-header">Lucky Kite</div>
  </div>
  <div class="d-flex flex-column col-12">
    <div class="d-flex flex-row flex-wrap justify-content-center">
      <div class="d-flex flex-column total-clicks-header metric-container col-3">
        <div class="metric-container-header p-1 ">Total Engaged</div>
        <div class="metric-value-text m-1">{{ new Intl.NumberFormat('en-US').format(engagementMetricData.TotalEngaged) }}</div>
      </div>
      <div class="d-flex flex-column total-clicks-header metric-container col-3">
        <div class="metric-container-header p-1 ">Unique Engaged</div>
        <div class="metric-value-text m-1">{{ new Intl.NumberFormat('en-US').format(engagementMetricData.UniqueEngaged) }}</div>
      </div>
      <div class="d-flex flex-column metric-container col-3">
        <div class="metric-container-header p-1">Total Games</div>
        <div class="metric-value-text m-1">{{ new Intl.NumberFormat('en-US').format(engagementMetricData.TotalGames) }}</div>
      </div>
      <div class="d-flex flex-column metric-container col-3">
        <div class="metric-container-header p-1">Credit Used</div>
        <div class="metric-value-text m-1">{{ new Intl.NumberFormat('en-US').format(engagementMetricData.CreditsUsed) }}</div>
      </div>
      <div class="d-flex flex-column metric-container col-3">
        <div class="metric-container-header p-1">Cashed Out</div>
        <div class="metric-value-text m-1">{{ new Intl.NumberFormat('en-US').format(engagementMetricData.CashedOut) }}</div>
      </div>
    </div>
    <div class="d-flex flex-column align-items-center mt-2 mb-2 col-12">
      <div class="col-11" style="overflow-x:auto;">
        <table class="table table-hover" id="tblToExcl">
          <thead>
          <tr>
            <th class="col-3" scope="col">Date</th>
            <th class="col-3" scope="col">Total Engaged</th>
            <th class="col-3" scope="col">Unique Engaged</th>
            <th class="col-3" scope="col">Total Games</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(day, index) in dailyEngagementTable.slice().reverse()" :key=index>
            <td> {{ Helpers.dateConversion(day.Date.slice(0, 10)) }}</td>
            <td> {{ new Intl.NumberFormat('en-US').format(day.TotalEngaged) }}</td>
            <td> {{ new Intl.NumberFormat('en-US').format(day.UniqueEngaged) }}</td>
            <td> {{ new Intl.NumberFormat('en-US').format(day.TotalGames) }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <button class="d-flex flex-column justify-content-center export-button p-2 mb-3" @click="htmlTableToExcel()">Export to Excel</button>
    </div>
  </div>
</template>

<script>
import flatpickr from "flatpickr";
import LineChart from "@/views/components/charts/LineChart";
import {mapMutations, mapState} from "vuex";
import moment from "moment";
import DashboardService from "@/services/dashboardService";
import * as XLSX from 'xlsx/xlsx.mjs';

export default {
  name: "ProductDailyEngagementUnlockMore",
  inheritAttrs: false,
  props: ['engagementMetric'],
  components: {
    LineChart
  },
  computed: {
    ...mapState(['productConfig', 'startingDateCalendar', 'endingDateCalendar'])
  },
  data() {
    return {
      dateRangePicked: `${moment().subtract(7, 'd').format('YYYY-MM-DD')} to ${moment().subtract(1, 'd').format('YYYY-MM-DD')}`,
      engagementMetricData: [],
      startingDatePicked: undefined,
      endDatePicked: undefined,
      daily: 0,
      dailyEngagementTable: [],
    }
  },
  methods: {
    ...mapMutations(['setTriggerNewChart', 'setLineChartData', 'setLineChartToLoad', 'setStartingDateCalendar', 'setEndingDateCalendar']),
    async confirmDateRangePicked(dateRange) {
      this.sliceDateRange(dateRange);
      await this.setNewEngagementMetrics()
      this.setTriggerNewChart(true)
      this.setLineChartData(this.engagementMetricData.dateDailyBreakdown)
    },
    sliceDateRange(dateRange) {
      this.startingDatePicked = dateRange.slice(0, 10)
      this.endDatePicked = dateRange.substr(-10)
      this.setStartingDateCalendar(this.startingDatePicked)
      this.setEndingDateCalendar(this.endDatePicked)
    },
    async checkDateRangeChanged() {
      if (this.startingDateCalendar !== undefined) {
        this.setUpdatedDateRange()
        await this.setNewEngagementMetrics()
        this.triggerUpdatedChart()
      } else {
        this.setDefaultEngagementMetrics()
        this.setLineChartToLoad(this.productConfig.lineGraphLabels)
      }
    },
    async setNewEngagementMetrics() {
      let getEngagementMetricsResponse = await DashboardService.getUnlockMoreEngagementStats(`${this.startingDatePicked}`, `${this.endDatePicked}`, this.daily)
      this.updatedEngagementMetricsData = getEngagementMetricsResponse.data;
      this.engagementMetricData = this.updatedEngagementMetricsData;
      this.dailyEngagementTable = this.engagementMetricData.dateDailyBreakdown;
    },
    setUpdatedDateRange() {
      this.dateRangePicked = `${this.startingDateCalendar} to ${this.endingDateCalendar}`
      this.startingDatePicked = this.startingDateCalendar
      this.endDatePicked = this.endingDateCalendar
    },
    setDefaultEngagementMetrics() {
      this.engagementMetricData = this.engagementMetric;
      this.dailyEngagementTable = this.engagementMetricData.dateDailyBreakdown;
    },
    triggerUpdatedChart() {
      this.setTriggerNewChart(true)
      this.setLineChartData(this.engagementMetricData.dateDailyBreakdown)
      this.setBarChartToLoad(this.productConfig.totalPlaysGraph)
    },
    htmlTableToExcel() {
      let table_elt = document.getElementById("tblToExcl");
      let workbook = XLSX.utils.table_to_book(table_elt);
      XLSX.writeFile(workbook, `Lucky Kite Daily Engagement Report.xlsb`);
    },
  },
  async beforeMount() {
    await this.checkDateRangeChanged()
  },
  mounted() {
    flatpickr('#datepicker', {
      mode: "range"
    })
  },
}
</script>

<style scoped>
.overview-button, .confirm-button, .export-button {
  height: 35px;
  border-radius: 18px;
  font-weight: 600;
  font-size: 20px;
  border: 0;
  background-color: #EE5C36;
  color: #FFFFFF;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

input {
  text-align: center;
}

.metric-container {
  border-radius: 12px;
  font-weight: 600;
  border: 0;
  background-color: #F4D1CE;
  color: #000000;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 250px;
  margin: 20px;
}

.metric-container-header {
  font-size: 18px;
}

.metric-value-text {
  font-size: 30px;
  padding-left: 3px;
}

.total-clicks-header {
  position: relative;
  font-size: 25px;
}

.service-header {
  font-size: 20px;
  font-weight: 700;
}


@media only screen and (max-width: 576px) {
  .overview-button, .confirm-button {
    height: 28px;
    font-size: 18px;
  }

  input {
    font-size: 10.5px;
  }

  .metric-container-header {
    font-size: 13px;
  }

  .metric-value-text {
    font-size: 16px;
  }

  .metric-container {
    width: 25%;
    margin-bottom: -5px;
  }

  .total-clicks-header {
    font-size: 20px;
  }

  table {
    margin-top: 20px;
    font-size: 10.5px;
  }

  .export-button {
    height: 28px;
    font-size: 15px;
  }
}
</style>
