import {createStore} from 'vuex'

export default createStore({
    state: {
        userData: undefined,
        userProductAccess : undefined,
        isLoading: false,
        productConfig: undefined,
        reportingMetric: undefined,
        startingDateCalendar: undefined,
        endingDateCalendar: undefined,
        triggerNewChart: false,
        barChartDataUpdated: undefined,
        barChartToLoad: undefined,
        lineChartDataUpdated: undefined,
        lineChartToLoad: undefined,
        sideBarMenuVisible: false,
    },
    mutations: {
        setUserData(state, userData) {
            state.userData = userData;
        },
        setUserProductAccess(state, userProductAccess) {
            state.userProductAccess = userProductAccess;
        },
        setIsLoading(state, isLoading) {
            state.isLoading = isLoading
        },
        setProductConfig(state, productConfig) {
            state.productConfig = productConfig
        },
        setReportingMetric(state, reportingMetric) {
            state.reportingMetric = reportingMetric
        },
        setStartingDateCalendar(state, startingDateCalendar) {
            state.startingDateCalendar = startingDateCalendar
        },
        setEndingDateCalendar(state, endingDateCalendar) {
            state.endingDateCalendar = endingDateCalendar
        },
        setTriggerNewChart(state, triggerNewChart) {
            state.triggerNewChart = triggerNewChart
        },
        setBarChartData(state, barChartDataUpdated) {
            state.barChartDataUpdated = barChartDataUpdated
        },
        setBarChartToLoad(state, barChartToLoad) {
            state.barChartToLoad = barChartToLoad
        },
        setLineChartData(state, lineChartDataUpdated) {
            state.lineChartDataUpdated = lineChartDataUpdated
        },
        setLineChartToLoad(state, lineChartToLoad) {
            state.lineChartToLoad = lineChartToLoad
        },
        setSidebarMenuVisible(state, sideBarMenuVisible) {
            state.sideBarMenuVisible = sideBarMenuVisible
        },
    },
    actions: {},
    modules: {}
})
