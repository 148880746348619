<template>
  <div class="d-flex flex-column justify-content-center col-12 mt-3">
    <div class="d-flex flex-row justify-content-around col-12">
      <button class="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-2 overview-button">Overview</button>
      <input v-model="dateRangePicked" class="col-4 col-md-4 col-sm-5 col-xl-3" placeholder="Select Date..." type="text"
             id="datepicker" autocomplete="off"/>
      <button @click="confirmDateRangePicked(dateRangePicked)"
              class="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-2 confirm-button">Confirm
      </button>
    </div>
    <div class="d-flex flex-column align-items-center mt-2">
      <div class="d-flex flex-row justify-content-center col-10 col-sm-8 col-md-6 col-lg-4 col-xl-3 radio-container">
        <div class="d-flex flex-row align-items-center justify-content-center mt-2 col-6">
          <input @change="confirmDateRangePicked(dateRangePicked)" type="radio" id="freeUsers" value="4070"
                 v-model="hyveService"/>
          <label for="freeUsers">Free to Play</label>
        </div>
        <div class="d-flex flex-row align-items-center justify-content-center mt-2 col-6">
          <input @change="confirmDateRangePicked(dateRangePicked)" type="radio" id="vipUsers" value="4069"
                 v-model="hyveService"/>
          <label for="vipUsers">VIP Users</label>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column align-items-center col-12 mt-5">
      <BarChart :ussdWebDataMetric="ussdWebDataMetric[1]"></BarChart>
    </div>
    <div class="d-flex flex-row flex-wrap justify-content-center mt-5">
      <div
          class="d-flex flex-column justify-content-center text-center col-4 col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-2 p-1 m-1 metric-container">
        <div class="metric-container-title m-1">
          Av. Daily Unique Web
        </div>
        <div class="metric-container-number m-1">
          {{
            new Intl.NumberFormat('en-US').format(Math.trunc(comparisonMetricsData.web_unique_player_engagement / dateRangeCount))
          }}
        </div>
      </div>
      <div
          class="d-flex flex-column justify-content-center text-center col-4 col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-2 p-1 m-1 m-1 metric-container">
        <div class="metric-container-title m-1">
          Av. Daily Unique USSD
        </div>
        <div class="metric-container-number m-1">
          {{
            new Intl.NumberFormat('en-US').format(Math.trunc(comparisonMetricsData.ussd_unique_player_engagement / dateRangeCount))
          }}
        </div>
      </div>
      <div
          class="d-flex flex-column justify-content-center text-center col-4 col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-2 p-1 m-1 m-1 metric-container">
        <div class="metric-container-title m-1">
          Web Session Count
        </div>
        <div class="metric-container-number m-1">
          {{ new Intl.NumberFormat('en-US').format(comparisonMetricsData.total_web_session_count) }}
        </div>
      </div>
      <div
          class="d-flex flex-column justify-content-center text-center col-4 col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-2 p-1 m-1 m-1 metric-container">
        <div class="metric-container-title m-1">
          USSD Session Count
        </div>
        <div class="metric-container-number m-1">
          {{ new Intl.NumberFormat('en-US').format(comparisonMetricsData.total_ussd_session_count) }}
        </div>
      </div>
      <div
          class="d-flex flex-column justify-content-center text-center col-4 col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-2 p-1 m-1 m-1 metric-container">
        <div class="metric-container-title m-1">
          Av. Daily Unique Players
        </div>
        <div class="metric-container-number m-1">
          {{
            ((Math.trunc(comparisonMetricsData.ussd_unique_player_engagement) + Math.trunc(comparisonMetricsData.web_unique_player_engagement)) / dateRangeCount).toFixed()
          }}
        </div>
      </div>
    </div>
    <div class="d-flex flex-row flex-wrap justify-content-center mt-2">
      <div
          class="d-flex flex-column justify-content-center text-center col-4 col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-2 p-1 m-1 m-1 metric-container">
        <div class="metric-container-title m-1">
          Web Questions Answered
        </div>
        <div class="metric-container-number m-1">
          {{ new Intl.NumberFormat('en-US').format(comparisonMetricsData.web_questions_answered) }}
        </div>
      </div>
      <div
          class="d-flex flex-column justify-content-center text-center col-4 col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-2 p-1 m-1 m-1 metric-container">
        <div class="metric-container-title m-1">
          USSD Questions Answered
        </div>
        <div class="metric-container-number m-1">
          {{ new Intl.NumberFormat('en-US').format(comparisonMetricsData.ussd_questions_answered) }}
        </div>
      </div>
      <div
          class="d-flex flex-column justify-content-center text-center col-4 col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-2 p-1 m-1 m-1 metric-container">
        <div class="metric-container-title m-1">
          Web Players Awarded
        </div>
        <div class="metric-container-number m-1">
          {{ new Intl.NumberFormat('en-US').format(comparisonMetricsData.web_winner_num) }}
        </div>
      </div>
      <div
          class="d-flex flex-column justify-content-center text-center col-4 col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-2 p-1 m-1 m-1 metric-container">
        <div class="metric-container-title m-1">
          USSD Players Awarded
        </div>
        <div class="metric-container-number m-1">
          {{ new Intl.NumberFormat('en-US').format(comparisonMetricsData.ussd_winner_numx) }}
        </div>
      </div>
    </div>
    <div class="d-flex flex-row flex-wrap justify-content-around mt-2 mb-2">
      <div
          class="d-flex flex-column justify-content-center text-center col-4 col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-2 p-1 m-1  m-1 metric-container">
        <div class="metric-container-title m-1">
          USSD Correct Ratio
        </div>
        <div class="metric-container-number m-1">
          {{
            ((comparisonMetricsData.ussd_answered_correctly / comparisonMetricsData.ussd_questions_asked)).toFixed(2)
          }}
        </div>
      </div>
      <div
          class="d-flex flex-column justify-content-center text-center col-4 col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-2 p-1 m-1 m-1 metric-container">
        <div class="metric-container-title m-1">
          Web Correct Ratio
        </div>
        <div class="metric-container-number m-1">
          {{ ((comparisonMetricsData.web_answered_correctly / comparisonMetricsData.web_questions_asked)).toFixed(2) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import flatpickr from "flatpickr";
import {mapMutations, mapState} from "vuex";
import BarChart from "@/views/components/charts/BarChart";
import DashboardService from "@/services/dashboardService";

export default {
  name: "ProductUssdWeb",
  props: ['ussdWebDataMetric'],
  computed: {
    ...mapState(['startingDateCalendar', 'endingDateCalendar', 'productConfig'])
  },
  components: {
    BarChart
  },
  data() {
    return {
      dateRangePicked: `${moment().subtract(7, 'd').format('YYYY-MM-DD')} to ${moment().subtract(1, 'd').format('YYYY-MM-DD')}`,
      startingDatePicked: undefined,
      endDatePicked: undefined,
      comparisonMetricsData: [],
      dateRangeCount: undefined,
      hyveService: 4070,
    }
  },
  methods: {
    ...mapMutations(['setIsLoading', 'setTriggerNewChart', 'setBarChartData', 'setBarChartToLoad', 'setStartingDateCalendar', 'setEndingDateCalendar']),
    async confirmDateRangePicked(dateRange) {
      this.sliceDateRange(dateRange);
      await this.getUssdWebDataMetrics();
      this.setTriggerNewChart(true);
      this.setBarChartData(this.updatedUssdWebDataMetricsData);
    },
    sliceDateRange(dateRange) {
      this.startingDatePicked = dateRange.slice(0, 10);
      this.endDatePicked = dateRange.substr(-10);
      this.setStartingDateCalendar(this.startingDatePicked);
      this.setEndingDateCalendar(this.endDatePicked);
    },
    setUpdatedDateRange() {
      this.dateRangePicked = `${this.startingDateCalendar} to ${this.endingDateCalendar}`;
      this.startingDatePicked = this.startingDateCalendar;
      this.endDatePicked = this.endingDateCalendar;
    },
    async getUssdWebDataMetrics() {
      this.checkHyveService()
      let getUssdWebDataMetricsDataResponse = (await DashboardService.getQuizIncUssdWebStats(this.startingDatePicked, this.endDatePicked, this.productConfig.productId, this.hyveService)).data;
      this.updatedUssdWebDataMetricsData = getUssdWebDataMetricsDataResponse.recordsets[1]
      this.comparisonMetricsData = getUssdWebDataMetricsDataResponse.recordsets[0][0]
      this.dateRangeCount = this.updatedUssdWebDataMetricsData.length
    },
    checkHyveService() {
      if (this.hyveService === 'null') this.hyveService = null
    },
    triggerUpdatedChart() {
      this.setTriggerNewChart(true)
      this.setBarChartData(this.updatedUssdWebDataMetricsData)
      this.setBarChartToLoad(this.productConfig.ussdWebGraph)
    },
    setDefaultComparisonMetrics() {
      this.comparisonMetricsData = this.ussdWebDataMetric[0][0]
      this.dateRangeCount = this.ussdWebDataMetric[1].length
    },
    async checkDateRangeChanged() {
      if (this.startingDateCalendar !== undefined) {
        this.setUpdatedDateRange()
        await this.getUssdWebDataMetrics()
        this.triggerUpdatedChart()
      } else {
        this.setDefaultComparisonMetrics()
        this.setBarChartToLoad(this.productConfig.ussdWebGraph)
      }
    },
  },
  async beforeMount() {
    await this.checkDateRangeChanged()
    flatpickr('#datepicker', {
      mode: "range"
    })
  }
}
</script>

<style scoped>
.overview-button, .confirm-button {
  height: 35px;
  border-radius: 18px;
  font-weight: 600;
  font-size: 20px;
  border: 0;
  background-color: #EE5C36;
  color: #FFFFFF;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

input {
  text-align: center;
}

.metric-container {
  border-radius: 12px;
  font-weight: 600;
  border: 0;
  background-color: #F4D1CE;
  color: #000000;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.metric-container-number {
  font-size: 22px;
}

@media only screen and (max-width: 576px) {
  .overview-button, .confirm-button {
    height: 28px;
    font-size: 18px;
  }

  input {
    font-size: 10.5px;
  }

  .radio-container {
    font-size: 12px;
  }
}
</style>
