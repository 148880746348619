<template>
  <div class="d-flex flex-column align-items-center">
    <div class="row align-items-center justify-content-center text-center reporting-metrics-container p-1 col-12">
      <div class="col-sm-3">
        <button @click="reportingMetricButtonClicked('productSubscription', 0)"
                :class="`col-6 col-lg-9 col-md-12 col-sm-12 m-1 ${reportingButtonIndex === 0 ? 'reporting-metrics-button-active' : 'reporting-metrics-button-default' }`">
          Subscription
        </button>
      </div>
      <div class="col-sm-3">
        <button @click="reportingMetricButtonClicked('productEngagementDaily', 1)"
                :class="`col-6 col-lg-9 col-md-12 col-sm-12 m-1 ${reportingButtonIndex === 1 ? 'reporting-metrics-button-active' : 'reporting-metrics-button-default' }`">
          Daily Engagement
        </button>
      </div>
      <div v-if="userData.SMSRights" class="col-sm-3">
        <button @click="reportingMetricButtonClicked('acquisitionCampaign', 2)"
                :class="`col-6 col-lg-9 col-md-12 col-sm-12 m-1 ${reportingButtonIndex === 2 ? 'reporting-metrics-button-active' : 'reporting-metrics-button-default' }`">
          Outreach
        </button>
      </div>
    </div>
    <div class="d-flex flex-column align-items-center col-12" v-if="this.reportingMetricToShow === 'productSubscription'">
      <ProductSubscriptionUnlockMore :subscriptionMetric="subscriptionMetricsData"></ProductSubscriptionUnlockMore>
    </div>
    <div class="d-flex flex-column align-items-center col-12" v-if="this.reportingMetricToShow === 'productEngagementDaily'">
      <ProductDailyEngagementUnlockMore :engagementMetric="dailyEngagementMetricsData"></ProductDailyEngagementUnlockMore>
    </div>
    <div class="d-flex flex-column align-items-center col-12" v-if="this.reportingMetricToShow === 'acquisitionCampaign' && userData.SMSRights">
      <SdpAcquisitionSmsCampaign></SdpAcquisitionSmsCampaign>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import moment from "moment";
import DashboardService from "@/services/dashboardService";
import ProductSubscriptionUnlockMore from "@/views/components/metrics/ProductSubscriptionUnlockMore";
import ProductDailyEngagementUnlockMore from "@/views/components/metrics/ProductDailyEngagementUnlockMore";
import SdpAcquisitionSmsCampaign from "@/views/components/smsCampaign/mtn/acquisition/SdpAcquisitionSmsCampaign";

export default {
  name: "UnlockMore",
  components: {ProductDailyEngagementUnlockMore, ProductSubscriptionUnlockMore, SdpAcquisitionSmsCampaign},
  computed: {
    ...mapState(['productConfig', 'userData'])
  },
  data() {
    return {
      reportingMetricToShow: undefined,
      reportingButtonIndex: undefined,
      todayDate: `${moment().subtract(1, 'd').format('YYYY-MM-DD')}`,
      startingDate: `${moment().subtract(7, 'd').format('YYYY-MM-DD')}`,
      subscriptionMetricsData: [],
      hourlyEngagementMetricsData: [],
      dailyEngagementMetricsData: [],
      subscriptionServiceId: null,
      hourly: 1,
      daily: 0,
    }
  },
  methods: {
    ...mapMutations(['setReportingMetric', 'setSidebarMenuVisible']),
    setReportingMetricToShow() {
      this.reportingMetricToShow = this.productConfig.initialMetricToDisplay;
      this.setReportingMetric(this.reportingMetricToShow);
      this.reportingButtonIndex = 0;
    },
    async reportingMetricButtonClicked(metricType, index) {
      await this.loadProductData(metricType);
      this.todayDate = `${moment().subtract(7, 'd').format('YYYY-MM-DD')}`;
      this.startingDate = `${moment().subtract(7, 'd').format('YYYY-MM-DD')}`;
      this.reportingMetricToShow = metricType;
      this.reportingButtonIndex = index;
      this.setReportingMetric(this.reportingMetricToShow);
    },
    async loadProductData(metricType) {
      const metricFunctions = {
        'productSubscription': this.getSubscriptionDataMetrics,
        'productEngagementDaily': this.getDailyEngagementDataMetrics,
      };
      const selectedFunction = metricFunctions[metricType];
      if (selectedFunction) await selectedFunction.call(this);
    },
    async getSubscriptionDataMetrics() {
      this.subscriptionMetricsData = (await DashboardService.getUnlockMoreSubscriptionStats(`${this.startingDate}`, `${this.todayDate}`, this.subscriptionServiceId)).data;
    },
    async getDailyEngagementDataMetrics() {
      this.dailyEngagementMetricsData = (await DashboardService.getUnlockMoreEngagementStats(`${this.startingDate}`, `${this.todayDate}`, this.daily)).data;
    },
    checkUserAccessRights() {
      return (this.userData.MTN);
    }
  },
  async beforeMount() {
    const userHasAccess = this.checkUserAccessRights();
    if (userHasAccess) {
      this.setSidebarMenuVisible(false);
      await this.loadProductData('productSubscription');
      this.setReportingMetricToShow();
    } else {
      this.$router.push(`/login`);
    }
  },
  watch: {
    '$store.state.reportingMetric': function () {
      if (this.$store.state.reportingMetric === 'productSubscription') {
        this.reportingMetricToShow = this.productConfig.initialMetricToDisplay
        this.reportingButtonIndex = 0;
      }
    }
  }
}
</script>

<style scoped>
.reporting-metrics-container {
  background-color: #E6E6E6;
  border-radius: 16px;
}

.reporting-metrics-button-default, .reporting-metrics-button-active {
  border-radius: 20px;
  border: 0;
  height: 40px;
  font-weight: 700;
  font-size: 20px;
}

.reporting-metrics-button-active {
  background-color: #EE5C36;
  color: #FFFFFF;
}

.reporting-metrics-button-default {
  background-color: #FFFFFF;
  color: #000000;
}

@media only screen and (max-width: 719px) {
  .reporting-metrics-button-default, .reporting-metrics-button-active {
    height: 28px;
    font-size: 16px;
  }
}
</style>
