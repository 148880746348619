<template>
  <div class="d-flex flex-column justify-content-center col-12 mt-3">
    <div class="d-flex flex-row justify-content-around col-12">
      <button class="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-2 overview-button">Overview</button>
      <input v-model="dateRangePicked" class="col-4 col-md-4 col-sm-5 col-xl-3" placeholder="Select Date..." type="text"
             id="datepicker" autocomplete="off"/>
      <button @click="confirmDateRangePicked(dateRangePicked)"
              class="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-2 confirm-button">Confirm
      </button>
    </div>
    <div v-if="!productConfig.endpoint.includes('fantasy')" class="d-flex flex-row mt-3">
      <div class="d-flex flex-column align-items-center col-8 bar-chart-container">
        <BarChart :engagementMetric="engagementMetric.UniquePlayers"></BarChart>
      </div>
      <div class="d-flex flex-column align-items-center mt-4 col-4">
        <div class="d-flex flex-column engagement-metric-container-bar col-10 col-sm-8 col-md-7 col-xl-6">
          <div class="metric-container-header p-1">Av. Daily Unique Players</div>
          <div class="metric-value-text p-1">
            {{ new Intl.NumberFormat('en-US').format(engagementMetricData.TotalUniquePlayers) }}
          </div>
          <div class="d-flex flex-row text-center align-items-center pb-1">
            <div class="col-2"><img class="col-6"
                                    :src="Helpers.findArrowImageModule(Helpers.deltaPercentage(engagementMetricData.TotalUniquePlayers , engagementMetricData.TotalUniquePlayersDelta))">
            </div>
            <div
                :class="`${Helpers.setTextColour(Helpers.deltaPercentage(engagementMetricData.TotalUniquePlayers , engagementMetricData.TotalUniquePlayersDelta)) ? 'green-text' : 'red-text' }`">
              {{
                Helpers.deltaPercentage(engagementMetricData.TotalUniquePlayers, engagementMetricData.TotalUniquePlayersDelta)
              }}
            </div>
          </div>
        </div>
        <div v-if="engagementMetricData.TotalUSSDSessions"
             class="d-flex flex-column engagement-metric-container-bar col-10 col-sm-8 col-md-7 col-xl-6 mt-3">
          <div class="metric-container-header p-1">Total Sessions</div>
          <div class="metric-value-text p-1">{{
              new Intl.NumberFormat('en-US').format((engagementMetricData.TotalUSSDSessions + engagementMetricData.TotalWebSessions))
            }}
          </div>
          <div class="d-flex flex-row text-center align-items-center pb-1">
            <div class="col-2"><img class="col-6"
                                    :src="Helpers.findArrowImageModule(Helpers.deltaPercentage((engagementMetricData.TotalUSSDSessions + engagementMetricData.TotalWebSessions) , (engagementMetricData.TotalUSSDSessionsDelta + engagementMetricData.TotalWebSessionsDelta) ))">
            </div>
            <div
                :class="`${Helpers.setTextColour(Helpers.deltaPercentage((engagementMetricData.TotalUSSDSessions + engagementMetricData.TotalWebSessions) , (engagementMetricData.TotalUSSDSessionsDelta + engagementMetricData.TotalWebSessionsDelta) )) ? 'green-text' : 'red-text' }`">
              {{
                Helpers.deltaPercentage((engagementMetricData.TotalUSSDSessions + engagementMetricData.TotalWebSessions), (engagementMetricData.TotalUSSDSessionsDelta + engagementMetricData.TotalWebSessionsDelta))
              }}
            </div>
          </div>
        </div>
        <div v-if="engagementMetricData.TotalWebEngaged"
             class="d-flex flex-column engagement-metric-container-bar col-10 col-sm-8 col-md-7 col-xl-6 mt-3">
          <div class="metric-container-header p-1">Total Web Sessions</div>
          <div class="metric-value-text p-1">
            {{ new Intl.NumberFormat('en-US').format(engagementMetricData.TotalWebEngaged) }}
          </div>
          <div class="d-flex flex-row text-center align-items-center pb-1">
            <div class="col-2"><img class="col-6"
                                    :src="Helpers.findArrowImageModule(Helpers.deltaPercentage(engagementMetricData.TotalWebEngaged , engagementMetricData.TotalWebEngagedDelta))">
            </div>
            <div
                :class="`${Helpers.setTextColour(Helpers.deltaPercentage(engagementMetricData.TotalWebEngaged , engagementMetricData.TotalWebEngagedDelta)) ? 'green-text' : 'red-text' }`">
              {{
                Helpers.deltaPercentage(engagementMetricData.TotalWebEngaged, engagementMetricData.TotalWebEngagedDelta)
              }}
            </div>
          </div>
        </div>
        <div v-if="engagementMetricData.UniqueMonthlyLogins"
             class="d-flex flex-column engagement-metric-container-bar col-10 col-sm-8 col-md-7 col-xl-6 mt-3">
          <div class="metric-container-header p-1">Total Unique Monthly Logins*</div>
          <div class="metric-value-text p-1">
            {{ new Intl.NumberFormat('en-US').format(engagementMetricData.UniqueMonthlyLogins) }}
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex flex-row justify-content-between container-fluid col-12 mt-2 mb-3">
      <div v-if="engagementMetricData.QuestionsServed"
           class="d-flex flex-column engagement-metric-container p-1 col-3 col-xs-3 col-sm-3 col-md-2 col-lg-3 col-xl-2 mt-3">
        <div class="metric-container-header p-1">Questions Served</div>
        <div class="metric-value-text p-1">
          {{ new Intl.NumberFormat('en-US').format(engagementMetricData.QuestionsServed) }}
        </div>
        <div class="d-flex flex-row text-center align-items-center pb-1">
          <div class="col-2"><img class="col-6"
                                  :src="Helpers.findArrowImageModule(Helpers.deltaPercentage(engagementMetricData.QuestionsServed , engagementMetricData.QuestionsServedHistoricDelta))">
          </div>
          <div
              :class="`${Helpers.setTextColour(Helpers.deltaPercentage(engagementMetricData.QuestionsServed , engagementMetricData.QuestionsServedHistoricDelta)) ? 'green-text' : 'red-text' }`">
            {{
              Helpers.deltaPercentage(engagementMetricData.QuestionsServed, engagementMetricData.QuestionsServedHistoricDelta)
            }}
          </div>
        </div>
      </div>
      <div v-if="engagementMetricData.QuestionsServed"
           class="d-flex flex-column engagement-metric-container p-1 col-3 col-xs-3 col-sm-3 col-md-2 col-lg-3 col-xl-2 mt-3">
        <div class="metric-container-header p-1">Correct Answers</div>
        <div class="metric-value-text p-1">{{
            new Intl.NumberFormat('en-US').format(engagementMetricData.CorrectAnswers)
          }}
        </div>
        <div class="d-flex flex-row text-center align-items-center pb-1">
          <div class="col-2"><img class="col-6"
                                  :src="Helpers.findArrowImageModule(Helpers.deltaPercentage(engagementMetricData.CorrectAnswers , engagementMetricData.TotalCorrectAnswersDelta))">
          </div>
          <div
              :class="`${Helpers.setTextColour(Helpers.deltaPercentage(engagementMetricData.CorrectAnswers , engagementMetricData.TotalCorrectAnswersDelta)) ? 'green-text' : 'red-text' }`">
            {{
              Helpers.deltaPercentage(engagementMetricData.CorrectAnswers, engagementMetricData.TotalCorrectAnswersDelta)
            }}
          </div>
        </div>
      </div>
      <div v-if="engagementMetricData.QuestionsServed"
           class="d-flex flex-column engagement-metric-container p-1 col-3 col-xs-3 col-sm-3 col-md-2 col-lg-3 col-xl-2 mt-3">
        <div class="metric-container-header p-1">Answer Ratio</div>
        <div class="metric-value-text p-1">
          {{ ((engagementMetricData.CorrectAnswers / engagementMetricData.QuestionsServed) * 100).toFixed(2) + '%' }}
        </div>
      </div>
      <div v-if="engagementMetricData.PlayersAwarded"
           class="d-flex flex-column engagement-metric-container p-1 col-3 col-xs-3 col-sm-3 col-md-2 col-lg-3 col-xl-2 mt-3">
        <div class="metric-container-header p-1">Players Awarded</div>
        <div class="metric-value-text p-1">{{
            new Intl.NumberFormat('en-US').format(engagementMetricData.PlayersAwarded)
          }}
        </div>
      </div>
    </div>

    <div class="d-flex flex-row justify-content-around container-fluid col-12 mb-3">
      <div
          class="d-flex flex-column engagement-metric-container p-1 col-2">
        <div class="metric-container-header p-1">Level 1 User Count</div>
        <div class="metric-value-text p-1">
          {{ new Intl.NumberFormat('en-US').format(engagementMetricData.LevelOneUserCount) }}
        </div>
      </div>
      <div
          class="d-flex flex-column engagement-metric-container p-1 col-2">
        <div class="metric-container-header p-1">Level 2 User Count</div>
        <div class="metric-value-text p-1">
          {{ new Intl.NumberFormat('en-US').format(engagementMetricData.LevelTwoUserCount) }}
        </div>
      </div>
      <div
          class="d-flex flex-column engagement-metric-container p-1 col-2">
        <div class="metric-container-header p-1">Level 3 User Count</div>
        <div class="metric-value-text p-1">
          {{ new Intl.NumberFormat('en-US').format(engagementMetricData.LevelThreeUserCount) }}
        </div>
      </div>
    </div>
    <div class="d-flex flex-row justify-content-around container-fluid col-12 mb-3">
      <div
          class="d-flex flex-column engagement-metric-container p-1 col-2">
        <div class="metric-container-header p-1">Level 4 User Count</div>
        <div class="metric-value-text p-1">
          {{ new Intl.NumberFormat('en-US').format(engagementMetricData.LevelFourUserCount) }}
        </div>
      </div>
      <div
          class="d-flex flex-column engagement-metric-container p-1 col-2">
        <div class="metric-container-header p-1">Level 5 User Count</div>
        <div class="metric-value-text p-1">
          {{ new Intl.NumberFormat('en-US').format(engagementMetricData.LevelFiveUserCount) }}
        </div>
      </div>
      <div
          class="d-flex flex-column engagement-metric-container p-1 col-2">
        <div class="metric-container-header p-1">Level 6 User Count</div>
        <div class="metric-value-text p-1">
          {{ new Intl.NumberFormat('en-US').format(engagementMetricData.LevelSixUserCount) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BarChart from "@/views/components/charts/BarChart";
import flatpickr from "flatpickr";
import DashboardService from "@/services/dashboardService";
import {mapMutations, mapState} from "vuex";
import moment from "moment";

export default {
  name: "ChessMastersEngagement",
  inheritAttrs: false,
  props: ['engagementMetric'],
  components: {
    BarChart
  },
  computed: {
    ...mapState(['productConfig', 'startingDateCalendar', 'endingDateCalendar'])
  },
  data() {
    return {
      dateRangePicked: `${moment().subtract(7, 'd').format('YYYY-MM-DD')} to ${moment().subtract(1, 'd').format('YYYY-MM-DD')}`,
      engagementMetricData: [],
      startingDatePicked: undefined,
      endDatePicked: undefined,
    }
  },
  methods: {
    ...mapMutations(['setIsLoading', 'setTriggerNewChart', 'setBarChartData', 'setBarChartToLoad', 'setStartingDateCalendar', 'setEndingDateCalendar']),
    async confirmDateRangePicked(dateRange) {
      this.sliceDateRange(dateRange);
      await this.setNewEngagementMetrics();
      this.setTriggerNewChart(true)
      this.setBarChartData(this.updatedEngagementMetricsData.UniquePlayers)
    },
    sliceDateRange(dateRange) {
      this.startingDatePicked = dateRange.slice(0, 10)
      this.endDatePicked = dateRange.substr(-10)
      this.setStartingDateCalendar(this.startingDatePicked)
      this.setEndingDateCalendar(this.endDatePicked)
    },
    setUpdatedDateRange() {
      this.dateRangePicked = `${this.startingDateCalendar} to ${this.endingDateCalendar}`
      this.startingDatePicked = this.startingDateCalendar
      this.endDatePicked = this.endingDateCalendar
    },
    async setNewEngagementMetrics() {
      let getEngagementMetricsResponse = await DashboardService.getEngagementStats(`${this.startingDatePicked}`, `${this.endDatePicked}`, this.productConfig.productId)
      this.updatedEngagementMetricsData = getEngagementMetricsResponse.data
      this.engagementMetricData = this.updatedEngagementMetricsData
    },
    triggerUpdatedChart() {
      this.setTriggerNewChart(true)
      this.setBarChartData(this.updatedEngagementMetricsData.UniquePlayers)
      this.setBarChartToLoad(this.productConfig.engagementGraph)
    },
    setDefaultEngagementMetrics() {
      this.engagementMetricData = this.engagementMetric
    },
    async checkDateRangeChanged() {
      if (this.startingDateCalendar !== undefined) {
        this.setUpdatedDateRange()
        await this.setNewEngagementMetrics()
        this.triggerUpdatedChart()
      } else {
        this.setDefaultEngagementMetrics()
        this.setBarChartToLoad(this.productConfig.engagementGraph)
      }
    },
  },
  mounted() {
    flatpickr('#datepicker', {
      mode: "range"
    })
  },
  async beforeMount() {
    await this.checkDateRangeChanged()
  }
}
</script>

<style scoped>
.overview-button, .confirm-button {
  height: 35px;
  border-radius: 18px;
  font-weight: 600;
  font-size: 20px;
  border: 0;
  background-color: #EE5C36;
  color: #FFFFFF;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

input {
  text-align: center;
}

.engagement-metric-container, .engagement-metric-container-bar {
  border-radius: 12px;
  font-weight: 600;
  border: 0;
  background-color: #F4D1CE;
  color: #000000;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.metric-container-header, .red-text, .green-text {
  font-size: 18px;
}

.metric-value-text {
  font-size: 30px;
}

.red-text {
  color: #ff0000;
}

.green-text {
  color: #207520;
}

@media only screen and (max-width: 576px) {
  .metric-container-header, .red-text, .green-text {
    font-size: 12px;
  }

  .metric-value-text {
    font-size: 12px;
  }

  table {
    margin-top: 20px;
    font-size: 10.5px;
  }

  .overview-button, .confirm-button {
    height: 28px;
    font-size: 18px;
  }

  .engagement-metric-container {
    width: 24% !important;
  }

  input {
    font-size: 10px;
  }
}

</style>
