<template>
  <div class="vl-parent">
    <loading v-model:active="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"
             :color="color"/>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

export default {
  name: 'LoadingOverlay',
  data() {
    return {
      isLoading: true,
      fullPage: true,
      color: '#EE5C36',
    }
  },
  components: {
    Loading
  },
}
</script>
