<template>
  <div>
    <canvas id="line-chart"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js'
import {loadLineChartData} from "@/functions/charts/loadLineChartData";
import {mapMutations, mapState} from "vuex";

export default {
  name: 'LineChart',
  inheritAttrs: false,
  props: ['subscriptionMetric', 'dailyFiveEngagement', 'predictorEngagement', 'awardsMetric', 'engagementMetric'],
  computed: {
    ...mapState(['triggerNewChart', 'lineChartDataUpdated', 'lineChartToLoad', 'reportingMetric', 'productConfig'])
  },
  methods: {
    ...mapMutations(['setTriggerNewChart']),
    checkMetricDataToLoad() {
      let data = [this.subscriptionMetric, this.dailyFiveEngagement, this.predictorEngagement, this.awardsMetric, this.engagementMetric];
      let conditionsFiltering = [null, undefined, 0];
      this.metricDataToLoad = data.filter((item) => !conditionsFiltering.includes(item))[0]
    },
    getGraphLabel() {
      switch (this.reportingMetric) {
        case 'productSubscription':
        case 'productEngagementDaily':
          return this.productConfig.lineGraphLabels;
        case 'dailyFiveEngagement':
          return this.productConfig.dailyFiveLineGraphLabels;
        case 'predictorEngagement':
          return this.productConfig.predictorLineGraphLabels;
        case 'awardSpend':
          return ['MTN MzansiGames'];
        default:
          return undefined;
      }
    }
  },
  data() {
    return {
      lineChartData: undefined
    }
  },
  mounted() {
    const ctx = document.getElementById('line-chart');
    this.myLineChart = new Chart(ctx, this.lineChartData);
  },
  beforeMount() {
    this.checkMetricDataToLoad();
    this.lineChartData = loadLineChartData(this.metricDataToLoad, this.getGraphLabel(), this.productConfig.product)
  },
  watch: {
    '$store.state.triggerNewChart': function () {
      if (this.$store.state.triggerNewChart === true) {
        this.myLineChart.destroy()
        const ctx = document.getElementById('line-chart');
        this.lineChartData = loadLineChartData(this.lineChartDataUpdated, this.getGraphLabel(), this.productConfig.product)
        this.myLineChart = new Chart(ctx, this.lineChartData);
        this.setTriggerNewChart(false)
      }
    }
  }
}
</script>

<style>
canvas {
  width: 100% !important;
  height: 65vh !important;
}
</style>
