<template>
  <div class="d-flex flex-column align-items-center">
    <div class="row align-items-center justify-content-around text-center reporting-metrics-container col-12">
      <button @click="reportingMetricButtonClicked('productRevenue', 0)"
              :class="`col-6 col-lg-7 col-md-10 col-sm-12 m-1 ${reportingButtonIndex === 0 ? 'reporting-metrics-button-active' : 'reporting-metrics-button-default' }`">
        Revenue
      </button>
      <button @click="reportingMetricButtonClicked('productSubscription', 1)"
              :class="`col-6 col-lg-7 col-md-10 col-sm-12 m-1 ${reportingButtonIndex === 1 ? 'reporting-metrics-button-active' : 'reporting-metrics-button-default' }`">
        Subscription
      </button>
<!--      <button @click="reportingMetricButtonClicked('productEngagement', 2)"-->
<!--              :class="`col-6 col-lg-7 col-md-10 col-sm-12 m-1 ${reportingButtonIndex === 2 ? 'reporting-metrics-button-active' : 'reporting-metrics-button-default' }`">-->
<!--        Engagement-->
<!--      </button>-->
      <button v-if="userData.SMSRights" @click="reportingMetricButtonClicked('engagementCampaign', 3)"
              :class="`col-6 col-lg-7 col-md-10 col-sm-12 m-1 ${reportingButtonIndex === 3 ? 'reporting-metrics-button-active' : 'reporting-metrics-button-default' }`">
        Campaign
      </button>
      <button v-if="userData.SMSRights" @click="reportingMetricButtonClicked('acquisitionCampaign', 4)"
              :class="`col-6 col-lg-7 col-md-10 col-sm-12 m-1 ${reportingButtonIndex === 4 ? 'reporting-metrics-button-active' : 'reporting-metrics-button-default' }`">
        Outreach
      </button>
    </div>
    <div class="d-flex flex-column align-items-center col-12" v-if="this.reportingMetricToShow === 'productRevenue'">
      <ProductRevenue :revenueMetric="revenueMetricsData"></ProductRevenue>
    </div>
    <div class="d-flex flex-column align-items-center col-12"
         v-if="this.reportingMetricToShow === 'productSubscription'">
      <ProductSubscription :subscriptionMetric="subscriptionMetricsData"></ProductSubscription>
    </div>
<!--    <div class="d-flex flex-column align-items-center col-12" v-if="this.reportingMetricToShow === 'productEngagement'">-->
<!--      <ProductZoneEngagement :engagementMetric="engagementMetricsData"></ProductZoneEngagement>-->
<!--    </div>-->
    <div class="d-flex flex-column align-items-center col-12"
         v-if="this.reportingMetricToShow === 'engagementCampaign' && userData.SMSRights">
      <DcbEngagementSmsCampaign></DcbEngagementSmsCampaign>
    </div>
    <div class="d-flex flex-column align-items-center col-12"
         v-if="this.reportingMetricToShow === 'acquisitionCampaign' && userData.SMSRights">
      <DcbAcquisitionSmsCampaign></DcbAcquisitionSmsCampaign>
    </div>
  </div>
</template>

<script>
import ProductRevenue from "@/views/components/metrics/ProductRevenue";
import ProductSubscription from "@/views/components/metrics/ProductSubscription";
import ProductZoneEngagement from "@/views/components/metrics/ProductZoneEngagement";
import DcbEngagementSmsCampaign from "@/views/components/smsCampaign/vodacom/DcbEngagementSmsCampaign";
import DcbAcquisitionSmsCampaign from "@/views/components/smsCampaign/vodacom/DcbAcquisitionSmsCampaign";
import {mapMutations, mapState} from "vuex";
import DashboardService from "@/services/dashboardService";
import moment from "moment";

export default {
  name: "CricketZone",
  components: {
    ProductRevenue,
    ProductSubscription,
    DcbEngagementSmsCampaign,
    DcbAcquisitionSmsCampaign,
    ProductZoneEngagement
  },
  data() {
    return {
      reportingMetricToShow: undefined,
      reportingButtonIndex: undefined,
      revenueMetricsData: [],
      subscriptionMetricsData: [],
      engagementMetricsData: [],
      todayDate: `${moment().subtract(1, 'd').format('YYYY-MM-DD')}`,
      startingDate: `${moment().subtract(7, 'd').format('YYYY-MM-DD')}`,
    }
  },
  computed: {
    ...mapState(['isLoading', 'productConfig', 'userData'])
  },
  methods: {
    ...mapMutations(['setIsLoading', 'setReportingMetric', 'setSidebarMenuVisible']),
    async reportingMetricButtonClicked(metricType, index) {
      await this.loadProductData(metricType);
      this.reportingMetricToShow = metricType;
      this.reportingButtonIndex = index;
      this.setReportingMetric(this.reportingMetricToShow);
    },
    async getRevenueDataMetrics() {
      this.revenueMetricsData = (await DashboardService.getRevenueStats(`${this.startingDate}`, `${this.todayDate}`, this.productConfig.productId, null, null, 0)).data;
    },
    async getSubscriptionDataMetrics() {
      this.subscriptionMetricsData = (await DashboardService.getSubscriptionStats(`${this.startingDate}`, `${this.todayDate}`, this.productConfig.productId, null, undefined, 0)).data;
    },
    async getEngagementDataMetrics() {
      this.engagementMetricsData = (await DashboardService.getEngagementStats(`${this.startingDate}`, `${this.todayDate}`, this.productConfig.productId)).data;
    },
    async loadProductData(metricType) {
      const metricFunctions = {
        'productRevenue': this.getRevenueDataMetrics,
        'productSubscription': this.getSubscriptionDataMetrics,
        'productEngagement': this.getEngagementDataMetrics,
      };
      const selectedFunction = metricFunctions[metricType];
      if (selectedFunction) await selectedFunction.call(this);
    },
    setReportingMetricToShow() {
      this.reportingMetricToShow = this.productConfig.initialMetricToDisplay;
      this.setReportingMetric(this.reportingMetricToShow);
      this.reportingButtonIndex = 0;
    },
    checkUserAccessRights() {
      return (this.userData.Vodacom);
    }
  },
  async beforeMount() {
    const userHasAccess = this.checkUserAccessRights();
    if (userHasAccess) {
      this.setSidebarMenuVisible(false);
      await this.loadProductData('productRevenue');
      this.setReportingMetricToShow();
    } else {
      this.$router.push(`/login`);
    }
  },
  watch: {
    '$store.state.reportingMetric': function () {
      if (this.$store.state.reportingMetric === 'productRevenue') {
        this.reportingMetricToShow = this.productConfig.initialMetricToDisplay
        this.reportingButtonIndex = 0;
      }
    }
  }
}
</script>

<style scoped>
.reporting-metrics-container {
  background-color: #E6E6E6;
  border-radius: 16px;
}

.reporting-metrics-button-default, .reporting-metrics-button-active {
  border-radius: 20px;
  border: 0;
  height: 40px;
  font-weight: 700;
  font-size: 20px;
}

.reporting-metrics-button-active {
  background-color: #EE5C36;
  color: #FFFFFF;
}

.reporting-metrics-button-default {
  background-color: #FFFFFF;
  color: #000000;
}

button {
  width: 200px;
}

@media only screen and (max-width: 576px) {
  .reporting-metrics-button-default, .reporting-metrics-button-active {
    height: 28px;
    font-size: 17px;
  }
}

</style>
